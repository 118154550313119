import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

interface IProps {
  isPrivate: boolean;
  children: JSX.Element;
  allowMobileView?: boolean;
}

export default function ProtectedRoute(props: IProps) {
  const { isPrivate = true, children } = props;
  const key = localStorage.getItem('en');

  const referrer = `${location.pathname}${location.search}`;

  const isLoggedIn = useMemo(() => !isEmpty(key), [key]);

  if (isPrivate && !isLoggedIn) {
    return <Navigate to={`/login?referrer=${encodeURIComponent(referrer)}`} replace />;
  } else {
    return children;
  }
}
