import { useMutation, useQueryClient } from 'react-query';
import { CoreAPIs } from '../../../api';
import { PhoneNumberNode } from 'src/typings/call.types';
import { phoneNumbers } from 'src/queryClient/queryKeys';

type Params = {
  id: PhoneNumberNode['id'];
  payload: Pick<PhoneNumberNode, 'spam'>;
};

const updatePhoneNumber = async (params: Params): Promise<PhoneNumberNode> => {
  const data = await CoreAPIs.updatePhoneNumber(params.id, params.payload);
  return data;
};

export const useUpdatePhoneNumber = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<PhoneNumberNode, Error, Params, unknown>(updatePhoneNumber, {
    onSuccess: (responseData, params) => {
      queryClient.invalidateQueries([phoneNumbers]);
    },
  });
  return mutation;
};
