import React, { FC, memo } from 'react';
import clsx from 'clsx';
import { Modal } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { CloseButton } from '@components/Buttons';

export type CenterModalWithHeaderProps =
  | {
      children: React.ReactNode;
      width?: string;
      height?: string;
      isVisible?: boolean;
      onClose: () => void;
      shouldClose?: boolean;
      title?: string;
      className?: string;
      showModalWithoutTitle?: boolean;
    }
  | {
      children: React.ReactNode;
      width?: string;
      height?: string;
      isVisible?: boolean;
      shouldClose: boolean;
      onClose?: undefined;
      title: string;
      className?: string;
      showModalWithoutTitle?: boolean;
    };

export const CenterModalWithHeader: FC<CenterModalWithHeaderProps> = memo(
  ({ children, title, isVisible, onClose, shouldClose = true, className, showModalWithoutTitle = false }) => {
    const handleClose = () => {
      if (shouldClose) {
        onClose?.();
      }
    };

    return (
      <Modal open={Boolean(isVisible)} onClose={handleClose}>
        <div
          className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col bg-popupBgColor rounded-xl focus-visible:outline-none ${className}`}
        >
          {!showModalWithoutTitle && (
            <div
              className={clsx(
                shouldClose ? 'justify-between' : 'justify-center',
                'flex items-center px-6 h-10 w-full relative',
              )}
            >
              {title && <div className="text-textPrimary text-base font-semibold mr-5 pt-2">{title}</div>}
              {shouldClose === false ? null : (
                <div className="absolute right-2 top-2">
                  <CloseButton onClick={onClose} />
                </div>
              )}
            </div>
          )}
          <div className={twMerge('relative w-full h-auto', showModalWithoutTitle ? 'pt-2' : '')}>{children}</div>
        </div>
      </Modal>
    );
  },
);
