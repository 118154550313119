export type FileNode = {
  name: string;
  mimeType: FileMimeType;
  id: string;
};

export enum FileMimeType {
  Folder = 'application/vnd.google-apps.folder',
  Audio = 'audio/mpeg',
  Document = 'text/plain',
}
