import { useState } from 'react';
import get from 'lodash/get';
import { convertUTCToIST } from '@utils/time.utils';
import {
  PhoneIcon,
  CalendarIcon,
  ChatAltIcon,
  ClipboardCheckIcon,
  ClockIcon,
  PencilAltIcon,
  CheckIcon,
  XIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  ClipboardCopyIcon,
  DatabaseIcon,
  HandIcon,
  UsersIcon,
  TagIcon,
} from '@heroicons/react/outline';
import { DatePicker } from '@mui/lab';
import { IconButton } from '@mui/material';
import { getCallSummary, getUserIntentReason, getXMinutesFromNow } from '@utils/call.utils';
import moment from 'moment';
import { CallLogDetails } from 'src/typings/call.types';
import { CoreAPIs } from 'src/api';
import toast from 'react-hot-toast';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin?: boolean;
};

export default function CallSummary({ callDetails, allowAdmin }: Props) {
  const [dueDate, setDueDate] = useState<Date>(getXMinutesFromNow(5));
  const [editScheduledDate, toggleScheduledDate] = useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(
    callDetails?.borrower_calls?.call_scheduled_time || null,
  );

  const updateScheduledDate = async () => {
    try {
      await CoreAPIs.updateCallInfo(callDetails.borrower_calls?.id, {
        borrower_id: callDetails?.borrower_calls?.borrower_id,
        call_scheduled_time: moment(dueDate).utc().format('YYYY-MM-DDTHH:mm:ss'),
      });
      toggleScheduledDate(false);
      setScheduledDate(dueDate);
      toast.success('Next call schedule updated');
    } catch (error) {
      toast.error('Please select time at least 5mins from now');
      console.error('Error::', error);
    }
  };

  const onCopyPhone = (phone: string) => {
    navigator.clipboard.writeText(phone);
  };

  if (!callDetails) return null;

  const crt = get(callDetails, 'customer_requested_time', null);
  const customerRequestedTime = crt ? moment(convertUTCToIST(crt)).format('lll') : 'NA';
  const nextScheduledData = scheduledDate ? moment(convertUTCToIST(scheduledDate)).format('lll') : 'NA';
  const promisedDate = get(callDetails, 'ptp_datetime', 'NA');
  const prevCallContext = callDetails?.call_summary ? getCallSummary(callDetails?.call_summary) : null;

  return (
    <div className="flex flex-col gap-4 text-sm">
      <div className="flex justify-start items-center gap-3">
        <div className="text-primary text-xl font-semibold">{callDetails?.borrower?.name || '-'}</div>
        <div className="flex justify-start items-center gap-1 hover:bg-blue-50 px-2 hover:rounded-xl">
          <PhoneIcon className="w-5 h-5 text-primaryButton" />
          {callDetails?.borrower?.phone_number || '-'}

          <IconButton onClick={() => onCopyPhone(callDetails?.borrower?.phone_number || '-')}>
            <ClipboardCopyIcon className="w-5 h-5" />
          </IconButton>
        </div>
      </div>
      <div className="flex justify-start items-center gap-x-1 min-h-6">
        <CalendarIcon className="w-5 h-5 text-primaryButton" />
        <div>{moment(callDetails?.call_start).format('MMM Do YY')}</div>
        <div>@</div>
        <div>
          {moment(callDetails?.call_start).format('LT')} - {moment(callDetails?.call_end).format('LT')}
        </div>
        <div>({callDetails.duration}s)</div>
      </div>

      {false && allowAdmin && (
        <div className="flex justify-start items-center gap-x-1 h-6">
          <ClockIcon className="w-5 h-5 text-primaryButton" />
          <div>Expected call time :</div>

          {!editScheduledDate ? (
            <div className="flex justify-start items-center gap-x-2">
              <div>{nextScheduledData}</div>
              {false && (
                <IconButton onClick={() => toggleScheduledDate(true)}>
                  <PencilAltIcon className="w-5 h-5 text-primaryButton" />
                </IconButton>
              )}
            </div>
          ) : (
            <div className="flex justify-start items-center gap-x-2">
              <DatePicker
                showTimeInput
                selected={editScheduledDate ? dueDate : callDetails?.borrower_calls?.call_scheduled_time || dueDate}
                minTime={getXMinutesFromNow(5)}
                onChange={(date: Date) => setDueDate(date)}
                className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                excludeTimes={[getXMinutesFromNow(5)]}
                dateFormat="MMMM d, yyyy h:mm aa"
                timeInputLabel="Time:"
              />
              {false && (
                <div className="flex justify-end items-center gap-x-2">
                  <IconButton onClick={updateScheduledDate} title="Update">
                    <CheckIcon className="w-5 h-5 text-primaryButton" />
                  </IconButton>
                  <IconButton onClick={() => toggleScheduledDate(false)} title="Cancel">
                    <XIcon className="w-5 h-5 text-red-500" />
                  </IconButton>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className="grid grid-flow-row gap-y-4">
        <div className="grid grid-flow-col grid-cols-12 gap-x-10">
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <ClockIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Due Date:</span>
            <span className="">{get(callDetails, 'borrower.due_date', '-')}</span>
          </div>
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <PhoneIncomingIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Customer Requested time:</span>
            <span className="">{customerRequestedTime}</span>
          </div>
        </div>
        <div className="grid grid-flow-col grid-cols-12 gap-x-10">
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <HandIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Customer Promised time:</span>
            <span className="">{promisedDate ? moment(convertUTCToIST(promisedDate)).format('lll') : 'NA'}</span>
          </div>
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <PhoneOutgoingIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Expected call time:</span>
            <span className="">{nextScheduledData}</span>
          </div>
        </div>
      </div>

      <div className="grid grid-flow-col grid-cols-12 gap-x-10">
        {/* <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
          <DatabaseIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
          <span className="min-w-[100px] text-blue-950">Borrower context until now: </span>
          <span className="">
            {get(prevCallContext, 'delay_reason', null) || get(prevCallContext, 'summary', null)}
          </span>
        </div> */}
        <div className="col-span-6  flex justify-start items-start gap-x-1 min-h-6">
          <ChatAltIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
          <span className="min-w-[100px] text-blue-950">Intent:</span>
          <span className="">{getUserIntentReason(callDetails?.intent)}</span>
        </div>
        <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
          <ClipboardCheckIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
          <span className="min-w-[100px] text-blue-950">Intent Analysis:</span>
          <span className="">{callDetails?.reason}</span>
        </div>
      </div>

      {false && (
        <div className="grid grid-flow-col grid-cols-12 gap-x-10">
          <div className="col-span-6  flex justify-start items-start gap-x-1 min-h-6">
            <ChatAltIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Delay reason:</span>
            <span className="">{callDetails?.delay_reason}</span>
          </div>
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <TagIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Borrower Sentiment:</span>
            <span className="">{callDetails?.borrower_sentiment}</span>
          </div>
        </div>
      )}

      {false && (
        <div className="grid grid-flow-col grid-cols-12 gap-x-10">
          <div className="col-span-6  flex justify-start items-start gap-x-1 min-h-6">
            <ChatAltIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Borrower Request:</span>
            <span className="">{callDetails?.borrower_request}</span>
          </div>
          <div className="col-span-6 flex justify-start items-start gap-x-1 min-h-6">
            <UsersIcon className="min-w-[20px] min-h-[20px] w-5 h-5 text-primaryButton" />
            <span className="min-w-[100px] text-blue-950">Agent next action:</span>
            <span className="">{callDetails?.agent_next_action}</span>
          </div>
        </div>
      )}
    </div>
  );
}
