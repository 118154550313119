import { SearchIcon } from '@heroicons/react/outline';
import { twMerge } from 'tailwind-merge';

interface IProps {
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder: string;
  value: string;
  handleChange: (val: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  isDisabled?: boolean;
}

export default function SearchInput(props: IProps) {
  const { inputRef, placeholder, value, handleChange, onKeyDown, onFocus, isDisabled = false } = props;

  const handleInputChange = (val: string) => {
    handleChange(val);
  };

  return (
    <div className="flex justify-start items-center relative gap-x-2 text-textSecondary w-full">
      <SearchIcon className="w-5 h-5 absolute top-[10px] left-2 text-iconLight" />
      <input
        type="text"
        ref={inputRef}
        placeholder={placeholder}
        value={value || ''}
        onChange={(event) => handleInputChange(event.target.value)}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        autoComplete={''}
        disabled={isDisabled}
        className={twMerge(
          'caret-textPrimary h-10 px-4 pl-10 border bg-inputBgColor placeholder:text-inputPlaceholderColor rounded-lg focus:ring-primaryButton focus:border-primaryButton block w-full sm:text-sm text-md text-textPrimary disabled:bg-textDisabled disabled:text-textTertiary',
          'border-borderColor',
        )}
      />
    </div>
  );
}
