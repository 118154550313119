import { SkeletonLoader } from '@components/SkeletonLoader';

type Props = {};

export default function BarChartLoader({}: Props) {
  return (
    <div className="flex justify-start items-end gap-x-4">
      <SkeletonLoader height={200} width={70} />
      <SkeletonLoader height={60} width={70} />
      <SkeletonLoader height={100} width={70} />
      <SkeletonLoader height={50} width={70} />
      <SkeletonLoader height={100} width={70} />
      <SkeletonLoader height={180} width={70} />
      <SkeletonLoader height={120} width={70} />
      <SkeletonLoader height={170} width={70} />
      <SkeletonLoader height={100} width={70} />
      <SkeletonLoader height={180} width={70} />
    </div>
  );
}
