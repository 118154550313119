import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetBorrowerStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload } from 'src/typings/stats.types';

type Props = {
  startDate: StatsPayload['start_date'];
  companyId: StatsPayload['company_id'];
  endDate?: StatsPayload['end_date'];
};

export default function BorrowerStats({ startDate, endDate, companyId }: Props) {
  const payload = {
    company_id: companyId,
    metric_type: CallStatsMetricType.BORROWER_STATS,
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
  };

  const { data, isLoading } = useGetBorrowerStats(payload);

  return (
    <div className="shadow-lg rounded-lg p-2 w-1/2 bg-white h-[400px]">
      <div className="text-sm font-medium mb-4">Borrower Details</div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-full">Fetching borrower details....</div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={100} height={100} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="total_borrowers" stackId="a" fill="#82ca9d" />
            <Bar dataKey="new_borrowers" stackId="a" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
