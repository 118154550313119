import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { borrowerCallHistory } from 'src/queryClient/queryKeys';
import { BorrowerHistoryNode, BorrowerNode } from 'src/typings/call.types';

type Payload = {
  borrowerId: BorrowerNode['id'];
};

const getBorrowerCallHistory = async ({ borrowerId }: Payload): Promise<BorrowerHistoryNode[]> => {
  const data = await CoreAPIs.getBorrowerCallHistory(borrowerId);
  return data;
};

export const useGetBorrowerCallHistory = (payload: Payload) => {
  const result = useQuery<BorrowerHistoryNode[], Error>([borrowerCallHistory], () => getBorrowerCallHistory(payload), {
    staleTime: ms('100min'),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: ms('100min'),
  });
  return result;
};
