import { AuthAPIs } from 'src/api';
import { clearUserCookies, getRefreshToken, setAccessToken, setRefreshToken } from '@utils/cookieUtils';
import { API_BASE } from 'src/api/config';
import { EventEmitter } from '@utils/Events';
import { getDataFromStorage } from '@utils/storageUtils';

export class Core {
  getBaseUrl() {
    return API_BASE;
  }

  getAccessToken() {
    const key = localStorage.getItem('en');
    return key;
  }

  getRefreshToken() {
    return getRefreshToken();
  }

  setAccessToken(accessToken: string) {
    setAccessToken(accessToken);
  }

  setRefreshToken(refreshToken: string) {
    setRefreshToken(refreshToken);
  }

  removeAccessToken() {
    clearUserCookies();
  }

  removeRefreshToken() {
    clearUserCookies();
  }

  async fetchNewAccessToken(refreshToken: string) {
    try {
      if (refreshToken) {
        const res = await AuthAPIs.refreshToken(refreshToken);
        const { access, refresh } = res;
        this.setAccessToken(access);
        refresh && this.setRefreshToken(refresh);
        // Update access and refresh token in context
        EventEmitter.emit('updateTokens', access, refresh);
        return;
      }
    } catch (error) {
      console.error('ERROR: fetchNewAccessToken', error);
      throw error;
    }
  }
}
