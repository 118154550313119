import { Core } from './clients';
import { Google } from './clients/Core';

export enum Client {
  CORE = 'core',
  GOOGLE = 'google',
}

export const clientFactory = {
  createClient: function (type: Client) {
    switch (type) {
      case Client.CORE:
        return new Core();
      case Client.GOOGLE:
        return new Google();
      default:
        return new Core();
    }
  },
};
