import React from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface IProps extends ButtonProps {
  propClass?: string;
}

export const SecondaryButton: React.FC<IProps> = ({ ...props }) => {
  const { children, propClass, ...rest } = props;

  return (
    <button
      type="button"
      {...rest}
      className={twMerge(
        'w-full h-10 inline-flex justify-center items-center rounded-xl font-semibold border border-primaryButton text-primaryButton text-base shadow-sm px-4 py-2 bg-navBarBgColor sm:text-sm',
        'hover:bg-navBarBgColor/90 hover:border-primaryButtonHover hover:text-primaryButtonHover',
        'disabled:text-primaryButtonDisabled disabled:bg-navBarBgColor disabled:border-primaryButtonDisabled disabled:cursor-not-allowed',
        propClass,
      )}
    >
      {children}
    </button>
  );
};
