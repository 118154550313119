export const convertUTCToIST = (utcDateStr: Date | string): Date => {
  // Parse the input date string to a Date object
  const utcDate = new Date(utcDateStr);

  // Check if the date is valid
  if (isNaN(utcDate.getTime())) {
    throw new Error('Invalid date');
  }

  // Convert the date to milliseconds and add the IST offset
  const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
  const istDate = new Date(utcDate.getTime() + istOffset);
  return istDate;
  //   // Format the IST date to desired string format
  //   const year = istDate.getUTCFullYear();
  //   const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  //   const day = String(istDate.getUTCDate()).padStart(2, '0');
  //   const hours = String(istDate.getUTCHours()).padStart(2, '0');
  //   const minutes = String(istDate.getUTCMinutes()).padStart(2, '0');
  //   const seconds = String(istDate.getUTCSeconds()).padStart(2, '0');

  //   return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};
