export interface IServerError {
  statusCode: number;
  message: string;
  meta: {
    errorMessage: string;
  };
  headers?: Record<string, string>;
}

type ErrorType = Partial<IServerError>;

export class ServerException extends Error {
  constructor({ statusCode, message, headers }: ErrorType) {
    super(message);

    this.message = message || 'An unknown error occurred';
    this.status = statusCode || 520;
    this.headers = headers || {};
  }

  public status: number;

  public message: string;

  public headers: Record<string, string>;

  public toString() {
    return `${this.status}: ${this.message}`;
  }

  public setHeaders(headers: Record<string, string>) {
    this.headers = headers;
  }
}
