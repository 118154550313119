import { SkeletonLoader } from '@components/SkeletonLoader';

type Props = {};

export default function FileLoader({}: Props) {
  return (
    <div className="flex flex-col gap-4 w-full">
      {Array.from({ length: 14 }).map((_, index) => (
        <SkeletonLoader key={`file-${index}`} width={'100%'} height={35} />
      ))}
    </div>
  );
}
