export type SentenceNode = {
  role: string;
  content: string;
};

export type STTNode = {
  results: {
    channels: Channels[];
  };
};

type Channels = {
  alternatives: Alternatives[];
};

export type Alternatives = {
  transcript: string;
  confidence: number;
  paragraphs: {
    transcript: string;
    paragraphs: ParagraphNode[];
  };
};

export type ParagraphTextNode = {
  text: string;
  start: number;
  end: number;
};

export enum Participants {
  User = 0,
  Agent = 1,
}

export type ParagraphNode = {
  sentences: ParagraphTextNode[];
  speaker: Participants;
  num_words: number;
  start: number;
  end: number;
};
