import { Tooltip } from '@components/Tooltip';
import { clsx } from 'clsx';
import React from 'react';

type Size = 'sm' | 'md' | 'lg';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type SizeMap = Record<Size, string>;

const sizeMap: SizeMap = { sm: 'w-4 h-4', md: 'w-6 h-6', lg: 'w-8 h-8' };

interface IProps extends ButtonProps {
  size?: Size;
  isSelected?: boolean;
  tooltip?: string;
  propClass?: string;
}

export const IconButton: React.FC<IProps> = (props) => {
  const { isSelected, children, size = 'md', tooltip, propClass, ...rest } = props;
  const dynamicClass = sizeMap[size];
  return (
    <Tooltip title={tooltip ?? ''} enterDelay={1000} enterNextDelay={2000} arrow>
      <button
        type="button"
        data-tour={tooltip}
        {...rest}
        className={clsx(
          isSelected ? 'bg-hoverBgColor' : undefined,
          dynamicClass,
          'min-w-[1.2rem] flex justify-center items-center rounded-lg hover:bg-hoverBgColor hover:rounded-lg focus:outline-none',
          propClass,
        )}
      >
        {children}
      </button>
    </Tooltip>
  );
};
