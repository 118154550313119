import { useEffect, useState } from 'react';
import { CenterModalWithHeader } from '@components/Modal/CenterModalWithHeader';
import { SentenceNode } from 'src/typings/deepgram.types';
import EditableSentenceNode from './EditableSentenceNode';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { AudioBar } from '@components/AudioBar';

type Props = {
  showModal: boolean;
  onClose: () => void;
  transcriptData: SentenceNode[];
  onSave: (d: SentenceNode[]) => void;
  blob: Blob;
};

export default function EditTranscript({ blob, showModal, onSave, onClose, transcriptData }: Props) {
  const [data, setData] = useState<SentenceNode[]>([...transcriptData]);
  const [audioURL, setAudioURL] = useState<string>('');

  const handleChange = (index: number, role: string, content: string) => {
    const newEle: SentenceNode = {
      role,
      content,
    };
    const newArr = [...data];
    newArr[index] = newEle;
    setData([...newArr]);
  };

  const onUpdate = () => {
    onSave([...data]);
    onClose();
  };

  const onAddNewRow = (index: number) => {
    const newArr = [...data];
    const newEmptyRow = {
      role: '',
      content: '',
    };
    newArr.splice(index + 1, 0, newEmptyRow);
    setData(newArr);
  };

  useEffect(() => {
    setData([...transcriptData]);
  }, [transcriptData]);

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setAudioURL(url);
    }
  }, [blob]);

  return (
    <CenterModalWithHeader
      title="Select Drive Folders"
      shouldClose
      isVisible={showModal}
      className="w-[1000px]"
      onClose={onClose}
    >
      <div className="p-6 min-h-[400px] max-h-[650px] h-full flex flex-col gap-4">
        <div>
          <AudioBar audioURL={audioURL} />
        </div>
        <div className="flex flex-col gap-4 pr-10 min-h-[400px] overflow-y-scroll">
          {data.length > 0 ? (
            data.map((item, index) => {
              return (
                <EditableSentenceNode
                  role={item.role}
                  content={item.content}
                  index={index}
                  handleChange={handleChange}
                  onAddNewRow={onAddNewRow}
                />
              );
            })
          ) : (
            <EditableSentenceNode
              role={''}
              content={''}
              index={0}
              handleChange={handleChange}
              onAddNewRow={onAddNewRow}
            />
          )}
        </div>
        <div className="flex justify-end items-center gap-x-4 mt-4">
          <SecondaryButton propClass="w-20" onClick={() => onClose()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton propClass="w-20" onClick={onUpdate}>
            Update
          </PrimaryButton>
        </div>
      </div>
    </CenterModalWithHeader>
  );
}
