import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Merge } from '../../typings/utils';
import { InputLabel } from './InputLabel';
import { ErrorDisplay } from '@components/ErrorDisplay';

type Size = 'small' | 'medium' | 'large';

export const SizeVariant: Record<Size, string> = {
  small: 'px-[0.62rem] py-[0.26rem] text-[0.74rem]',
  medium: 'px-[0.82rem] py-[0.34rem] text-[0.84rem]',
  large: 'px-[1.2rem] py-[0.4rem] text-[0.98rem]',
};

export type InputBaseProps = {
  error?: string | string[];
  containerProps?: string;
  inputContainerProps?: string;
  label?: string;
  LeftComponent?: React.ReactNode;
  size?: Size;
  RightOutsideComponent?: React.ReactNode;
  mandatory?: boolean;
  borderless?: boolean;
  showMultiLineError?: boolean;
};

export type TextFieldProps = Merge<React.InputHTMLAttributes<HTMLInputElement>, InputBaseProps>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      size = 'medium',
      mandatory = false,
      label,
      error,
      LeftComponent,
      containerProps,
      RightOutsideComponent,
      inputContainerProps,
      borderless = false,
      showMultiLineError = false,
      ...props
    },
    ref,
  ) => {
    const wheelHandler = (e: any) => {
      if (e.target.type === 'number') {
        e.target.blur();
      }
    };

    return (
      <div className={`flex w-full bg-transparent rounded-md  ${containerProps}`}>
        <div className="flex flex-col w-full p-0">
          {label ? <InputLabel label={label} mandatory={mandatory} /> : null}
          <div
            className={twMerge(
              `h-[40px] flex w-full bg-inputBgColor relative items-center rounded-xl overflow-hidden ${inputContainerProps}`,
            )}
          >
            {LeftComponent ? <div className="ml-2">{LeftComponent}</div> : null}
            <input
              className={twMerge(
                `caret-textPrimary text-textPrimary bg-inputBgColor h-full inline-block border border-gray-300 focus:border-primaryButton font-medium rounded-xl w-full disabled:bg-textDisabled disabled:cursor-not-allowed placeholder-shown:truncate placeholder-shown:text-inputPlaceholderColor ${SizeVariant[size]}`,
                borderless && 'bg-transparent disabled:bg-transparent',
                error?.length ? 'border-pastelRed focus:border-pastelRed' : '',
                borderless && error?.length === 0 && 'border-none focus:outline-none',
              )}
              onWheel={wheelHandler}
              {...props}
              ref={ref}
            />
          </div>
          <ErrorDisplay error={error} />
        </div>
        {RightOutsideComponent}
      </div>
    );
  },
);
