import { StatCompare } from '@components/StatCompare';
import moment from 'moment';
import { useGetBorrowerStats, useGetCallStats, useGetPickStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload } from 'src/typings/stats.types';

type Props = {
  companyId: StatsPayload['company_id'];
};

export default function TodaysStats({ companyId }: Props) {
  const today = new Date();
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const payload = {
    company_id: companyId,
    start_date: moment(today).format('YYYY-MM-DD'),
    end_date: moment(today).format('YYYY-MM-DD'),
  };

  const yesterdayPayload = {
    company_id: companyId,
    start_date: moment(yesterday).format('YYYY-MM-DD'),
    end_date: moment(yesterday).format('YYYY-MM-DD'),
  };

  const { data: pickStats } = useGetPickStats({ ...payload, metric_type: CallStatsMetricType.PICK_STATS });
  const { data: borrowerStats } = useGetBorrowerStats({ ...payload, metric_type: CallStatsMetricType.BORROWER_STATS });
  const { data: callStats } = useGetCallStats({ ...payload, metric_type: CallStatsMetricType.CALL_STATS });

  const { data: pickYStats } = useGetPickStats({ ...yesterdayPayload, metric_type: CallStatsMetricType.PICK_STATS });
  const { data: borrowerYStats } = useGetBorrowerStats({
    ...yesterdayPayload,
    metric_type: CallStatsMetricType.BORROWER_STATS,
  });
  const { data: callYStats } = useGetCallStats({ ...yesterdayPayload, metric_type: CallStatsMetricType.CALL_STATS });

  return (
    <div className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
      <div className="text-sm font-medium mb-4">Today's Stats</div>
      <div className="grid grid-flow-col gap-4">
        <div className="flex flex-col justify-start items-start gap-2">
          {borrowerStats && borrowerStats.length > 0 && (
            <>
              <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
                Total Borrowers :{' '}
                <div className="text-md font-bold flex justify-start items-center gap-x-1">
                  {borrowerStats[0].total_borrowers}
                  {borrowerYStats && borrowerYStats.length > 0 && (
                    <StatCompare
                      today={borrowerStats[0].total_borrowers}
                      yesterday={borrowerYStats[0].total_borrowers}
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
                New Borrowers :{' '}
                <div className="text-md font-bold flex justify-start items-center gap-x-1">
                  {borrowerStats[0].new_borrowers}
                  {borrowerYStats && borrowerYStats.length > 0 && (
                    <StatCompare today={borrowerStats[0].new_borrowers} yesterday={borrowerYStats[0].new_borrowers} />
                  )}
                </div>
              </div>
            </>
          )}
          {callStats && callStats.length > 0 && (
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              Total Calls :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {callStats[0].total_calls}
                {callYStats && callYStats.length > 0 && (
                  <StatCompare today={callStats[0].total_calls} yesterday={callYStats[0].total_calls} />
                )}
              </div>
            </div>
          )}
          {pickStats && pickStats.length > 0 && (
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              Total Borrowers Called today :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {pickStats[0].total_borrowers}
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare today={pickStats[0].total_borrowers} yesterday={pickYStats[0].total_borrowers} />
                )}
              </div>
            </div>
          )}
        </div>
        {pickStats && pickStats.length > 0 && (
          <div className="flex flex-col justify-start items-start gap-2">
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              Total Pickups :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {pickStats[0].total_pickups}
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare today={pickStats[0].total_pickups} yesterday={pickYStats[0].total_pickups} />
                )}
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              Unique Pickups :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {pickStats[0].unique_pickups}
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare today={pickStats[0].unique_pickups} yesterday={pickYStats[0].unique_pickups} />
                )}
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              Pickup Rate :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {(pickStats[0].pick_rate * 100).toFixed(2)}%
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare
                    today={pickStats[0].pick_rate * 100}
                    yesterday={pickYStats[0].pick_rate * 100}
                    type="percentage"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {pickStats && pickStats.length > 0 && (
          <div className="flex flex-col justify-start items-start gap-2">
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              PTP Count:{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {pickStats[0].ptp_count}
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare today={pickStats[0].ptp_count} yesterday={pickYStats[0].ptp_count} />
                )}
              </div>
            </div>
            <div className="flex justify-start items-center gap-x-2 text-blue-950 text-sm">
              PTP :{' '}
              <div className="text-md font-bold flex justify-start items-center gap-x-1">
                {(pickStats[0].ptp_rate * 100).toFixed(2)}%
                {pickYStats && pickYStats.length > 0 && (
                  <StatCompare
                    today={pickStats[0].ptp_rate * 100}
                    yesterday={pickYStats[0].ptp_rate * 100}
                    type="percentage"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
