import { getUserIntentReason } from '@utils/call.utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetBorrowerCallHistory } from 'src/hooks/apis/borrowers';
import { BorrowerNode } from 'src/typings/call.types';
import { twMerge } from 'tailwind-merge';

type Props = {
  borrowerId: BorrowerNode['id'];
};

export default function BorrowerHistory({ borrowerId }: Props) {
  const navigateTo = useNavigate();

  const { data, isLoading } = useGetBorrowerCallHistory({ borrowerId });

  const onRowPress = (id: number | string) => {
    navigateTo(`/dashboard/${id}`);
  };

  const borrowerHistory = useMemo(
    () => (data ? data.sort((a, b) => moment(b.call_start).diff(moment(a.call_start))) : []),
    [data],
  );

  if (isLoading) {
    return <div className="w-full h-40 flex justify-center items-center">Fetching borrower history...</div>;
  }

  return (
    <div className="w-full flex flex-col justify-start items-center gap-4">
      <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
        <thead className="border p-2 bg-gray-100 w-full">
          <tr className="border p-2 w-full">
            <td className="border p-2 text-sm font-bold text-blue-950">#</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call ID</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Agent Name</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call From</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Telephony</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Duration</td>
            <td className="border p-2 text-sm font-bold text-blue-950">User Intent</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call Started at</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call Ended at</td>
          </tr>
        </thead>

        <tbody>
          {borrowerHistory?.map((log, index) => {
            return (
              <tr
                className={twMerge('text-sm p-2 hover:bg-gray-200 cursor-pointer transition-all ease-in-out')}
                onClick={() => onRowPress(log.call_sid)}
              >
                <td className="border p-3">{index + 1}</td>
                <td className="border p-3">{log.call_sid}</td>
                <td className="border p-3">{log.agent}</td>
                <td className="border p-3">{log.call_from}</td>
                <td className="border p-3">{log.telephony}</td>
                <td className="border p-3">{log.duration}s</td>
                <td className="border p-3">{getUserIntentReason(log.intent)}</td>
                <td className="border p-3">{moment(log.call_start).format('lll')}</td>
                <td className="border p-3">{moment(log.call_end).format('lll')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
