import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { borrowerStats } from 'src/queryClient/queryKeys';
import { BorrowerStatsNode, StatsNode, StatsPayload } from 'src/typings/stats.types';

const getBorrowerStats = async (params: StatsPayload): Promise<BorrowerStatsNode[]> => {
  const data = await CoreAPIs.getBorrowerStats(params);
  return data.borrower_stats;
};

export const useGetBorrowerStats = (payload: StatsPayload) => {
  const result = useQuery<BorrowerStatsNode[], Error>(
    [borrowerStats, payload.metric_type, payload.company_id, payload.start_date, payload?.end_date],
    () => getBorrowerStats(payload),
    {
      staleTime: ms('20min'),
      ///enabled: enabled && Boolean(callId),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  return result;
};
