import { QueryClient } from 'react-query';

export const RETRY_COUNT = 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count) => {
        const shouldRetry = count <= RETRY_COUNT;
        return shouldRetry;
      },
    },
  },
});
