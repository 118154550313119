import { Card } from '@components/Card';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import BorrowerHistory from '../CallLogDetails/sections/BorrowerHistory';
import { BorrowerDetailNode } from 'src/typings/call.types';
import get from 'lodash/get';
import { UserIcon } from '@heroicons/react/outline';

type Props = {
  borrowerDetails: BorrowerDetailNode;
};

const tabs = [
  { id: 0, name: 'Information' },
  { id: 1, name: 'Borrower History' },
];

export default function ScheduledCallLogDetails({ borrowerDetails }: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const tabNodeRefArr = useRef<HTMLDivElement[]>([]);

  const left =
    tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().x -
      tabNodeRefArr.current[0]?.getBoundingClientRect().x || 0;

  const width = tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().width;

  return (
    <div>
      <Card>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-start items-center gap-x-2 relative w-full">
            {tabs?.map((tab, index: number) => {
              const isSelected = selectedIndex === index; //selectedResponseCode?.responseCode === res.responseCode;
              return (
                <div
                  key={`apiResponse-${index}`}
                  className={twMerge(`cursor-pointer p-2 font-medium`, isSelected ? 'py-2' : '')}
                  ref={(element: HTMLDivElement) => (tabNodeRefArr.current[index] = element)}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  {tab.name}
                </div>
              );
            })}
            <div
              className="h-1 bg-[#E8EFFA] absolute -bottom-1 left-0 rounded-lg transition-[left] ease-in-out duration-200"
              style={{ left, width: width || '100px' }}
            ></div>
          </div>

          <div className="w-full">
            {selectedIndex === 0 && (
              <div className="col-span-6 w-full">
                <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
                  <UserIcon className="w-4 h-4 text-primaryButton" />
                  Borrower Information
                </div>

                <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Name</td>
                    <td className="border p-2 text-sm font-medium">{get(borrowerDetails, 'Borrower.name', '-')}</td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Gender</td>
                    <td className="border p-2 text-sm font-medium">{get(borrowerDetails, 'Borrower.gender', '-')}</td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Company</td>
                    <td className="border p-2 text-sm font-medium">
                      {get(borrowerDetails, 'Borrower.company_id', '-')}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Due Date</td>
                    <td className="border p-2 text-sm font-medium">{get(borrowerDetails, 'Borrower.due_date', '-')}</td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                      Amount Due
                    </td>
                    <td className="border p-2 text-sm font-medium">
                      {get(borrowerDetails, 'Borrower.amount_due', '-')}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                      Total Amount Due
                    </td>
                    <td className="border p-2 text-sm font-medium">
                      {get(borrowerDetails, 'Borrower.total_amount', '-')}
                    </td>
                  </tr>
                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                      Customer Requested time
                    </td>
                    <td className="border p-2 text-sm font-medium">
                      {get(borrowerDetails, 'Borrower.customer_requested_time', '-')}
                    </td>
                  </tr>

                  <tr>
                    <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                      Penal Charges
                    </td>
                    <td className="border p-2 text-sm font-medium">
                      {get(borrowerDetails, 'Borrower.penal_charges', '-')}
                    </td>
                  </tr>
                </table>
              </div>
            )}
            {selectedIndex === 1 && <BorrowerHistory borrowerId={borrowerDetails?.Borrower.id} />}
          </div>
        </div>
      </Card>
    </div>
  );
}
