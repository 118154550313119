type Props = {
  audioURL: string;
  audioClass?: string;
};

export default function AudioBar({ audioURL, audioClass }: Props) {
  //   const handleDownload = () => {
  //     const link = document.createElement('a');
  //     link.href = audioURL;
  //     link.setAttribute('download', 'your-audio-file.mp3'); // or any other file name
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  return (
    <div className="">
      <audio src={audioURL} controls autoPlay={false} style={{ width: '' }} className={audioClass} />
      {/* <IconButton onClick={handleDownload}>
        <DownloadIcon className="w-6 h-6" />
      </IconButton> */}
    </div>
  );
}
