import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import map from 'lodash/map';
import { CallStatsNode, PickStatsNode } from 'src/typings/stats.types';

export const aggregateDataByTelephony = (data: PickStatsNode[]) => {
  // Group by telephony and date
  const grouped = groupBy(data, (item) => `${item.telephony}-${item.date}`);

  // Aggregate the total_pickups and unique_pickups
  const aggregated = map(grouped, (group) => {
    const totalPickups = sumBy(group, 'total_pickups');
    const uniquePickups = sumBy(group, 'unique_pickups');
    const totalBorrowers = sumBy(group, 'total_borrowers');
    const ptpCount = sumBy(group, 'ptp_count');
    const pickRate = (uniquePickups / totalBorrowers) * 100;

    return {
      date: group[0].date,
      telephony: group[0].telephony,
      total_pickups: totalPickups,
      unique_pickups: uniquePickups,
      // Include other fields if needed, here just taking the first element's values
      pick_rate: pickRate * 100,
      ptp_count: ptpCount,
      ptp_rate: group[0].ptp_rate,
      time_of_day: group[0].time_of_day,
      total_borrowers: totalBorrowers,
    };
  });

  // Group by telephony again
  return groupBy(aggregated, 'telephony');
};

export const aggregateDataByTime = (data: PickStatsNode[]) => {
  // Group by telephony and date
  const grouped = groupBy(data, (item) => `${item.time_of_day}-${item.date}`);

  // Aggregate the total_pickups and unique_pickups
  const aggregated = map(grouped, (group) => {
    return {
      date: group[0].date,
      telephony: group[0].telephony,
      total_pickups: sumBy(group, 'total_pickups'),
      unique_pickups: sumBy(group, 'unique_pickups'),
      // Include other fields if needed, here just taking the first element's values
      pick_rate: (sumBy(group, 'unique_pickups') / sumBy(group, 'total_borrowers')) * 100,
      ptp_count: sumBy(group, 'ptp_count'),
      ptp_rate: group[0].ptp_rate,
      time_of_day: group[0].time_of_day,
      total_borrowers: sumBy(group, 'total_borrowers'),
    };
  });

  // Group by telephony again
  return groupBy(aggregated, 'time_of_day');
};

const findIndexByDate = (date: string, data: PickStatsNode[] | CallStatsNode[]) => {
  const index = data.findIndex((i) => i.date === date);
  return index;
};

export const prepareTimeOfDayData = (
  morningData: PickStatsNode[],
  afternoonData: PickStatsNode[],
  eveningData: PickStatsNode[],
) => {
  const combinedData = morningData?.map((item) => {
    const afternoonIndex = findIndexByDate(item.date, afternoonData);
    const eveningIndex = findIndexByDate(item.date, eveningData);
    return {
      date: item.date,
      pickRate1: parseFloat((item.pick_rate * 100).toFixed(2)),
      pickRate2: afternoonIndex !== -1 ? parseFloat((afternoonData[afternoonIndex].pick_rate * 100).toFixed(2)) : 0,
      pickRate3: eveningIndex !== -1 ? parseFloat((eveningData[eveningIndex].pick_rate * 100).toFixed(2)) : 0,
      ptp1: (item.ptp_rate * 100).toFixed(2),
      ptp2: afternoonIndex !== -1 ? parseFloat((afternoonData[afternoonIndex].ptp_rate * 100).toFixed(2)) : 0,
      ptp3: eveningIndex !== -1 ? parseFloat((eveningData[eveningIndex].ptp_rate * 100).toFixed(2)) : 0,
    };
  });
  return combinedData;
};

export const prepareTelephonyData = (plivoData: PickStatsNode[], exotelData: PickStatsNode[]) => {
  const combinedData = plivoData?.map((item) => {
    const exotelIndex = findIndexByDate(item.date, exotelData);
    return {
      date: item.date,
      pickRate1: parseFloat((item.pick_rate * 100).toFixed(2)),
      pickRate2: exotelIndex !== -1 ? parseFloat((exotelData[exotelIndex].pick_rate * 100).toFixed(2)) : 0,
      ptp1: parseFloat((item.ptp_rate * 100).toFixed(2)),
      ptp2: exotelIndex !== -1 ? parseFloat((exotelData[exotelIndex].ptp_rate * 100).toFixed(2)) : 0,
    };
  });
  return combinedData;
};

export const prepareTelephonyTotalCallsData = (plivoData: CallStatsNode[], exotelData: CallStatsNode[]) => {
  const combinedData = plivoData?.map((item) => {
    const exotelIndex = findIndexByDate(item.date, exotelData);
    return {
      date: item.date,
      totalCalls1: item.total_calls,
      totalCalls2: exotelIndex !== -1 ? exotelData[exotelIndex].total_calls : 0,
    };
  });
  return combinedData;
};
