import { Spinner } from '@components/Spinner';

type SpinnerLoaderProps = {
  message?: string;
};

function FullPageLoader({ message }: SpinnerLoaderProps) {
  return (
    <div className="absolute top-0 left-0 right-0 w-full h-screen flex flex-col items-center justify-center bg-navBarBgColor">
      <Spinner size="large" />
      {message ? <p className="text-xl mt-6 font-medium text-gray-600">{message}</p> : null}
    </div>
  );
}

export default FullPageLoader;
