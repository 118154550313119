import { CallLogDetails, IntentReasonMap } from 'src/typings/call.types';
import { getUserIntentReason } from '@utils/call.utils';
import get from 'lodash/get';
import { useState } from 'react';
import { Dropdown } from '@components/Dropdown';
import { IconButton } from '@mui/material';
import { PencilAltIcon } from '@heroicons/react/outline';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { TextAreaField } from '@components/TextField';
import { useUpdateCallLogDetails } from 'src/hooks/apis/calls';
import toast from 'react-hot-toast';
import moment from 'moment';
import { convertUTCToIST } from '@utils/time.utils';
import { twMerge } from 'tailwind-merge';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin?: boolean;
};

const intentDropdownOptions = Object.entries(IntentReasonMap).map((intent) => {
  return {
    id: intent[0],
    name: intent[1],
  };
});

function createArray(length: number) {
  let result = [];
  for (let i = 1; i <= length; i++) {
    result.push({ id: i, name: i });
  }
  return result;
}

const manualRatingOptions = createArray(10);

export default function CallAnalysis({ callDetails, allowAdmin }: Props) {
  const { mutateAsync: updateDetails } = useUpdateCallLogDetails();
  const [editMode, toggleEditMode] = useState<boolean>(false);
  const [editIntentMode, toggleEditIntentMode] = useState<boolean>(false);
  const [manualScore, setManualScore] = useState<number>(get(callDetails, 'call_quality.call_quality_score_manual', 1));
  const [manualIntent, setManualIntent] = useState<number>(get(callDetails, 'manual_intent', -1));

  const [userAnalysis, setUserAnalysis] = useState<string>(
    get(callDetails, 'call_quality.call_quality_analysis_manual', ''),
  );

  const handleManualRatingChange = (val: any) => {
    if (val) {
      setManualScore(val.id);
    }
  };

  const handleManualIntentChange = (val: any) => {
    if (val) {
      setManualIntent(val.id);
    }
  };

  const onUpdateUserIntent = async () => {
    try {
      const payload = {
        manual_intent: manualIntent,
      };

      await updateDetails({ callId: callDetails.call_sid, payload });
      toggleEditIntentMode(false);
      toast.success('Manual Intent updated successfully!');
    } catch (error) {
      console.error('Error ::', error);
    }
  };

  const onUpdateUserFeedback = async () => {
    try {
      const payload = {
        call_quality: {
          ...callDetails?.call_quality,
          call_quality_score_manual: manualScore,
          call_quality_analysis_manual: userAnalysis,
        },
      };

      await updateDetails({ callId: callDetails.call_sid, payload });
      toggleEditMode(false);
      toast.success('User feedback added successfully!');
    } catch (error) {
      console.error('Error ::', error);
    }
  };

  const promisedDate = get(callDetails, 'ptp_datetime', 'NA');

  return (
    <div className="flex justify-between items-start gap-x-8">
      <div className="flex flex-col gap-y-4 w-1/2 my-4">
        <div className="flex flex-col w-full">
          <div className="flex justify-start items-center gap-x-2 font-semibold">Borrower Analysis</div>
          <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Promised time</td>
              <td className="border p-2 text-sm font-medium">
                {promisedDate ? moment(convertUTCToIST(promisedDate)).format('lll') : 'NA'}
              </td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Reason</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'ptp_reason', '-')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Delay reason</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'delay_reason', '-')}</td>
            </tr>

            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Borrower sentiment</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'borrower_sentiment', '-')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">
                Borrower sentiment reason
              </td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'borrower_sentiment_reason', '-')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Borrower Request</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'borrower_request', '-')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Agent next action</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'agent_next_action', '-')}</td>
            </tr>
          </table>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-start items-center gap-x-2 font-semibold">Borrower Intent</div>
          <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Intent</td>
              <td className="border p-2 text-sm font-medium">{getUserIntentReason(callDetails?.intent)}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm align-top font-medium">Reason</td>
              <td className="border p-2 text-sm font-medium">
                {getUserIntentReason(callDetails?.intent, callDetails?.reason)}
              </td>
            </tr>

            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">AI Call Score</td>
              <td className="border p-2 text-sm font-medium">
                {get(callDetails, 'call_quality?.call_quality_score_ai', '-')}
              </td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">AI Call Analysis</td>
              <td className="border p-2 text-sm font-medium">
                {get(callDetails, 'call_quality?.call_quality_analysis_ai', '-')}
              </td>
            </tr>
          </table>
        </div>
      </div>
      {allowAdmin && (
        <div className="flex flex-col gap-y-4 w-1/2">
          <div className="flex flex-col w-full my-4">
            <div className="flex justify-start items-center gap-x-2 font-semibold w-full">Manual Analysis</div>

            <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
              <tr>
                <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">User Score</td>
                <td className="border p-2 text-sm font-medium">
                  <div className="flex justify-between items-center gap-x-2">
                    {editMode ? (
                      <Dropdown
                        mandatory
                        placeholder="Select score"
                        defaultValue={get(callDetails, 'call_quality.call_quality_score_manual', '1')}
                        options={manualRatingOptions}
                        onSelect={handleManualRatingChange}
                      />
                    ) : (
                      get(callDetails, 'call_quality.call_quality_score_manual', '-')
                    )}

                    {!editMode && (
                      <IconButton onClick={() => toggleEditMode(true)} title="Edit">
                        <PencilAltIcon className="w-5 h-5 text-primaryButton" />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border p-2 bg-gray-100 text-blue-950 text-sm align-top font-medium">User Analysis</td>
                <td className="border p-2 text-sm font-medium">
                  <div className="flex justify-between items-center gap-x-2">
                    {editMode ? (
                      <TextAreaField
                        value={userAnalysis}
                        onChange={(e) => setUserAnalysis(e.target.value)}
                        placeholder="Add score analysis here..."
                      />
                    ) : (
                      get(callDetails, 'call_quality.call_quality_analysis_manual', '-')
                    )}

                    {!editMode && (
                      <IconButton onClick={() => toggleEditMode(true)} title="Edit">
                        <PencilAltIcon className="w-5 h-5 text-primaryButton" />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
              {editMode && (
                <tr>
                  <td></td>
                  <td>
                    <div className="flex justify-end items-center gap-x-2">
                      <IconButton onClick={onUpdateUserFeedback} title="Update">
                        <CheckIcon className="w-5 h-5 text-primaryButton" />
                      </IconButton>
                      <IconButton onClick={() => toggleEditMode(false)} title="Cancel">
                        <XIcon className="w-5 h-5 text-red-500" />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              )}
            </table>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex justify-start items-center gap-x-2 font-semibold">Manual Borrower Intent</div>
            <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
              <tr>
                <td className="border p-2 w-[200px] bg-gray-100 text-blue-950 align-top text-sm font-medium">
                  Manual Borrower Intent
                </td>
                <td className="border p-2 text-sm font-medium">
                  <div
                    className={twMerge(
                      'flex justify-between items-center gap-x-2 ',
                      editIntentMode ? 'pb-40 w-[300px]' : '',
                    )}
                  >
                    {editIntentMode ? (
                      <Dropdown
                        mandatory
                        placeholder="Select intent"
                        defaultValue={getUserIntentReason(get(callDetails, 'manual_intent', -1))}
                        options={intentDropdownOptions}
                        onSelect={handleManualIntentChange}
                      />
                    ) : (
                      getUserIntentReason(get(callDetails, 'manual_intent', -1))
                    )}

                    {!editIntentMode && (
                      <IconButton onClick={() => toggleEditIntentMode(true)} title="Edit">
                        <PencilAltIcon className="w-5 h-5 text-primaryButton" />
                      </IconButton>
                    )}
                  </div>
                </td>
              </tr>
              {editIntentMode && (
                <tr>
                  <td></td>
                  <td>
                    <div className="flex justify-end items-center gap-x-2">
                      <IconButton onClick={onUpdateUserIntent} title="Update">
                        <CheckIcon className="w-5 h-5 text-primaryButton" />
                      </IconButton>
                      <IconButton onClick={() => toggleEditIntentMode(false)} title="Cancel">
                        <XIcon className="w-5 h-5 text-red-500" />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              )}
              {/* <tr>
                <td className="border p-2 bg-gray-100 text-blue-950 text-sm align-top font-medium">Reason</td>
                <td className="border p-2 text-sm font-medium">
                  {getUserIntentReason(callDetails?.intent, callDetails?.reason)}
                </td>
              </tr> */}
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
