import { CallLogDetails } from 'src/typings/call.types';
import get from 'lodash/get';
import { parseLatencyValues } from '@utils/call.utils';

type Props = {
  callDetails: CallLogDetails;
};

export default function LatencyDetails({ callDetails }: Props) {
  return (
    <table className="border p-2 w-1/2 mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
      <thead className="border p-2 bg-gray-100 w-full">
        <tr className="border p-2 w-full">
          <td className="border p-2 text-sm font-bold text-blue-950">Latency</td>
          <td className="border p-2 text-sm font-bold text-blue-950">STT</td>
          <td className="border p-2 text-sm font-bold text-blue-950">LLM</td>
          <td className="border p-2 text-sm font-bold text-blue-950">TTS</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border p-2">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Average</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.avg_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Average</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.avg_stt_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Average</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.avg_llm_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Average</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.avg_tts_latency', 0))}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Min</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.min_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Min</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.min_stt_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Min</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.min_llm_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Min</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.min_tts_latency', 0))}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Max</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.max_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Max</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.max_stt_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Max</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.max_llm_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Max</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.max_tts_latency', 0))}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Median</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.median_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Median</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.median_stt_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Median</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.median_llm_latency', 0))}
              </div>
            </div>
          </td>
          <td className="border p-2 ">
            <div className="flex flex-col justify-start items-start gap-1">
              <div className="text-xs font-semibold text-blue-950">Median</div>
              <div className="text-sm font-bold">
                {parseLatencyValues(get(callDetails, 'latency_stats.median_tts_latency', 0))}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
