import { Skeleton, SkeletonProps } from '@mui/material';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

interface LoaderProps extends SkeletonProps {
  shimmers?: number;
  containerProps?: string;
}

export default function SkeletonLoader({ shimmers = 1, variant, width, height, containerProps }: LoaderProps) {
  if (shimmers > 1) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={`${containerProps} w-full`}
      >
        {[...Array(shimmers)].map((_, index: number) => (
          <Skeleton
            animation="wave"
            key={index}
            variant={variant || 'rounded'}
            width={width || '100%'}
            height={height || 20}
            className={twMerge(index !== 0 ? 'mt-4' : '', '!bg-shimmerColor !rounded-lg')}
          />
        ))}
      </motion.div>
    );
  }
  return (
    <Skeleton
      animation="wave"
      variant={variant || 'rounded'}
      width={width || '100%'}
      height={height || 20}
      className={'!bg-shimmerColor !rounded-lg'}
    />
  );
}
