import { useEffect, useState } from 'react';
import { PrimaryButton } from '@components/Buttons';
import { Dropdown } from '@components/Dropdown';
import { InputLabel, TextField } from '@components/TextField';
import { prepareMetadataFileContent } from '@utils/deepgram.utils';
import { useForm } from 'react-hook-form';
import { GoogleAPIs } from 'src/api';
import { IntentReasonMap } from 'src/typings/call.types';
import { FileNode } from 'src/typings/googleDrive.types';
import { TranscriptLoader } from '../loaders';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';

const intentDropdownOptions = Object.entries(IntentReasonMap).map((intent) => {
  return {
    id: intent[0],
    name: intent[1],
  };
});

type Props = {
  metadataFile?: FileNode | undefined;
  subTargetFolder?: FileNode | null;
  onUpdateFiles: () => void;
};

type FormData = {
  name: string;
  agent_name: string;
  amount_due: number;
  total_amount: number;
  due_date: Date;
  intent: string;
};

export default function CallMetadata({ metadataFile, subTargetFolder, onUpdateFiles }: Props) {
  const [isMetadataLoading, setIsMetadataLoading] = useState<boolean>(false);
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [intent, setIntent] = useState<string>('');
  const [isFileUpdating, setIsFileUpdating] = useState<boolean>(false);

  const [callMetaData, setCallMetaData] = useState<FormData>();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      name: callMetaData?.name || '',
      intent: callMetaData?.intent || '',
      amount_due: callMetaData?.amount_due || 0,
      total_amount: callMetaData?.total_amount || 0,
      due_date: callMetaData?.due_date || new Date(),
      agent_name: callMetaData?.agent_name || '',
    },
    mode: 'onSubmit',
  });

  const handleIntentChange = (val: any) => {
    if (val) {
      debugger;
      setIntent(val.name);
    }
  };

  const onSubmit = async () => {
    // const url = 'https://speech.knowl.io/api/call';
    const payload = {
      name: getValues().name,
      intent: intent,
      amount_due: getValues().amount_due,
      total_amount: getValues().total_amount,
      agent_name: getValues().agent_name,
      due_date: dueDate.toISOString().split('T')[0],
    };

    await onUpdateCallMetadata(payload);
  };

  const onUpdateCallMetadata = async (payload: FormData) => {
    try {
      setIsFileUpdating(true);
      setCallMetaData(payload);

      const fileContent = prepareMetadataFileContent(payload);

      const blob = new Blob([fileContent], { type: 'text/plain' });

      // Create metadata for the file
      const fileName = 'metadata';
      let metadata = {
        name: `${fileName}.json`, // File name
        mimeType: 'text/plain',
      };

      // Form the request body
      const formData = new FormData();

      if (metadataFile) {
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', blob);

        // API to update existing file
        await GoogleAPIs.updateFile(metadataFile.id, formData);
        toast.success('Metadata file was updated successfully!');
      } else if (subTargetFolder) {
        metadata = { ...metadata, parents: [subTargetFolder.id] };
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', blob);

        // API to create new file
        await GoogleAPIs.uploadFile(formData);
        toast.success('Metadata file was uploaded successfully!');
      }
      onUpdateFiles();
    } catch (error) {
      console.error('Something went wrong ::', error);
    } finally {
      setIsFileUpdating(false);
    }
  };

  const fetchFileContent = async (fileId: FileNode['id']) => {
    try {
      setIsMetadataLoading(true);
      const res = await GoogleAPIs.fetchFileContent(fileId);
      setCallMetaData(res);
      setValue('name', res.name);
      setValue('intent', res.intent);
      setValue('agent_name', res.agent_name);
      setValue('total_amount', res.total_amount);
      setValue('amount_due', res.amount_due);
      setValue('due_date', res.due_date);
    } catch (error) {
    } finally {
      setIsMetadataLoading(false);
    }
  };

  useEffect(() => {
    if (metadataFile) {
      fetchFileContent(metadataFile.id);
    }
  }, [metadataFile]);

  if (isMetadataLoading) {
    return (
      <div className="flex flex-col gap-4">
        <div className="font-medium">Loading metadata pls wait....</div>
        <TranscriptLoader />
      </div>
    );
  }

  return (
    <div>
      <form className="w-full flex flex-col gap-y-5 " onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex justify-between items-center gap-x-4">
          <TextField
            label="Name"
            placeholder="John Doe"
            mandatory
            {...register('name', {
              required: 'This field is mandatory',
              setValueAs: (value: string) => value.trim(),
            })}
          />
          <div className="w-1/2">
            <Dropdown
              mandatory
              placeholder="Select Intent"
              defaultValue={callMetaData?.intent}
              options={intentDropdownOptions}
              onSelect={handleIntentChange}
              label="Intent"
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-x-4 w-full">
          <TextField
            label="Amount Due"
            type="number"
            placeholder="50000"
            mandatory
            {...register('amount_due', {
              min: 0,
              valueAsNumber: true,
              required: 'This field is mandatory',
            })}
          />
          <TextField
            label="Loan Amount"
            placeholder="50000"
            type="number"
            mandatory
            {...register('total_amount', {
              min: 0,
              valueAsNumber: true,
              required: 'This field is mandatory',
            })}
          />

          <div className="flex flex-col">
            <InputLabel label="Due Date" mandatory />
            <DatePicker
              selected={dueDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) => setDueDate(date)}
              className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
            />
          </div>
        </div>

        <div className="flex justify-between items-center gap-x-4 w-full">
          <div className="w-1/3">
            <TextField
              label="Agent Name"
              placeholder="John Doe"
              mandatory
              {...register('agent_name', {
                required: 'This field is mandatory',
                setValueAs: (value: string) => value.trim(),
              })}
            />
          </div>
        </div>

        <div className="w-1/3 mt-4">
          <PrimaryButton type="submit" disabled={isFileUpdating || !isValid || isSubmitting}>
            Update
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
