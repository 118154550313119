import { CompanyNode } from './user.types';

export enum CallStatsMetricType {
  PICK_STATS = 'pick_stats',
  BORROWER_STATS = 'borrower_stats',
  CALL_STATS = 'call_stats',
  PAID_STATS = 'paid_stats',
}

export enum TimeOfDay {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export type StatsPayload = {
  metric_type: CallStatsMetricType;
  company_id: CompanyNode['id'];
  start_date: string;
  end_date?: string;
  telephony?: string;
  time_of_day?: TimeOfDay;
};

export type StatsNode = BorrowerStatsNode | PickStatsNode;

export type BorrowerStatsNode = {
  date: string;
  new_borrowers: number;
  total_borrowers: number;
};

export type PickStatsNode = {
  date: string;
  pick_rate: number;
  ptp_count: number;
  ptp_rate: number;
  telephony: string;
  time_of_day: TimeOfDay;
  total_borrowers: number;
  total_pickups: number;
  unique_pickups: number;
};

export type CallStatsNode = {
  date: string;
  total_calls: number;
};

export type PaidStatsNode = {
  borrowers_dropped: number;
};
