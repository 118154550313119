import { PhoneInput } from 'react-international-phone';
import { InputLabel } from '@components/TextField';
import { useState } from 'react';
import { Dropdown } from '@components/Dropdown';
import { Checkbox } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { useAddPhoneNumber } from 'src/hooks/apis/phoneNumbers';
import toast from 'react-hot-toast';

type Props = {
  onAdd: () => void;
  onClose: () => void;
};

const telephonyOptions = [
  { name: 'Plivo', id: 'plivo' },
  { name: 'Exotel', id: 'exotel' },
];

export default function AddPhone({ onAdd, onClose }: Props) {
  const { mutateAsync: addPhone } = useAddPhoneNumber();

  const [phone, setPhone] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [telephony, selectTelephony] = useState<string | null>(null);

  const handleTelephonyChange = (val: any) => {
    if (val) {
      selectTelephony(val.id);
    }
  };

  const onAddPhone = async () => {
    try {
      if (phone && telephony) {
        const payload = {
          phone_number: telephony === 'exotel' ? phone.split('+91')[1] : phone,
          telephony,
          spam: checked,
        };
        await addPhone({ payload });
        toast.success('New phone number added successfully');
        onAdd();
        setPhone('');
        selectTelephony(null);
      }
    } catch (error) {}
  };

  return (
    <div className="w-full rounded-xl border flex flex-col gap-4 p-4 shadow-md bg-white">
      <div className="flex flex-col gap-y-1">
        <InputLabel label="Phone number" mandatory />
        <PhoneInput
          defaultCountry="in"
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputClassName="rounded-xl w-full h-10 min-h-10 focus:ring-0"
          className="rounded-xl w-full h-10 min-h-10 "
          countrySelectorStyleProps={{
            className: 'h-10 min-h-10 rounded-xl w-12',
          }}
        />
      </div>
      <div className="w-full flex justify-start items-start gap-x-10">
        <Dropdown
          mandatory
          placeholder="Select Telephony"
          options={telephonyOptions}
          onSelect={handleTelephonyChange}
          label="Telephony Partner"
        />

        <div className="flex flex-col justify-start items-center gap-x-2">
          <InputLabel label="Is Spam?" />
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
        </div>
      </div>

      <div className="flex justify-start items-center gap-x-2">
        <PrimaryButton disabled={!phone || !telephony} onClick={onAddPhone} propClass="text-xs lg:text-md w-20 md:w-24">
          Add
        </PrimaryButton>
        <SecondaryButton propClass="text-xs lg:text-md w-20 md:w-24" onClick={onClose}>
          Cancel
        </SecondaryButton>
      </div>
    </div>
  );
}
