import { useEffect, useRef, useState } from 'react';
import { CoreAPIs } from 'src/api';
import { CallLogDetails } from 'src/typings/call.types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { CallEmotionNode, EmotionMetadata } from 'src/typings/callAnalysis.types';
import { getEmoji } from '@utils/emoji.utils';
import { twMerge } from 'tailwind-merge';

type Props = {
  callDetails: CallLogDetails;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
        <p className="intro">
          Likelihood of payment on day {label}: {(payload[0].value * 100).toFixed(0)}%
        </p>
      </div>
    );
  }

  return null;
};

export default function EmotionAnalysis({ callDetails }: Props) {
  const [analysis, setAnalysis] = useState<CallEmotionNode | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currentEmotionMetadata, setCurrentEmotionMetadata] = useState<EmotionMetadata | null>(null);
  const [currentEmojis, setCurrentEmojis] = useState<string[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);

  const getAnalysis = async () => {
    try {
      const url = await CoreAPIs.getCallEmotionAnalysis(callDetails?.call_sid);
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await res.json();
      setAnalysis(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      setCurrentTime(audioRef.current.currentTime);
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (analysis) {
      const currentTranscriptIndex = analysis.metadatas.findIndex(
        (segment) => currentTime >= segment.start && currentTime <= segment.end,
      );

      if (currentTranscriptIndex !== -1) {
        setCurrentIndex(currentTranscriptIndex);
        const currentTranscript = analysis.metadatas[currentTranscriptIndex];
        setCurrentEmotionMetadata(currentTranscript);
        const prosody = currentTranscript?.emotions.prosody;
        const burst = currentTranscript?.emotions.burst;
        const language = currentTranscript?.emotions.language;
        if (prosody !== null) {
          prosody.forEach((item) => {
            const emojiSearchResults = getEmoji(item.name);
            if (emojiSearchResults) {
              console.log(emojiSearchResults);
              setCurrentEmojis((oldArray) => [...oldArray, emojiSearchResults]);
            }
          });
        }
        if (language !== null) {
          language.forEach((item) => {
            const emojiSearchResults = getEmoji(item.name);
            if (emojiSearchResults) {
              console.log(emojiSearchResults);
              setCurrentEmojis((oldArray) => [...oldArray, emojiSearchResults]);
            }
          });
        }
        if (burst !== null) {
          burst.forEach((item) => {
            const emojiSearchResults = getEmoji(item.name);
            if (emojiSearchResults) {
              console.log(emojiSearchResults);
              setCurrentEmojis((oldArray) => [...oldArray, emojiSearchResults]);
            }
          });
        }
      } else {
        setCurrentEmotionMetadata(null);
        setCurrentEmojis([]);
        setCurrentIndex(-1);
      }
    }
  }, [analysis, currentTime]);

  useEffect(() => {
    getAnalysis();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className=" w-full h-[250px] mb-8">
        <div className="font-medium my-4">Emotion Analysis</div>
        <div className="flex justify-between items-center gap-x-4 h-[200px] w-full">
          <audio
            src={callDetails.recording}
            controls
            ref={audioRef}
            autoPlay={false}
            style={{ width: '400px', minWidth: '400px', margin: '1em' }}
          />
          {analysis?.metadatas && (
            <div className="flex flex-col justify-start items-start gap-y-2 w-full">
              {analysis?.metadatas.map((meta, index) => {
                const isActive = index === currentIndex;
                return (
                  <div
                    className={twMerge(
                      isActive || Math.abs(index - currentIndex) < 3 ? 'block text-lg px-10 w-full' : 'hidden',
                      'transition-all ease-in-out',
                    )}
                  >
                    <div
                      className={twMerge(
                        isActive ? 'font-extrabold text-3xl' : 'font-extralight text-sm',
                        Math.abs(index - currentIndex) > 1 ? 'opacity-15' : 'opacity-80',
                        'flex justify-start gap-x-10 items-center w-full transition-all ease-in-out',
                      )}
                    >
                      <div>{meta?.spoken_word}</div>
                      <div className="flex justify-start items-center gap-x-1 text-ellipsis w-[200px] ml-3">
                        {currentEmojis.slice(0, 7).map((i) => {
                          return <span className="text-md">{i}</span>;
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <hr className="w-full h-[1px] bg-gray-200 mt-4" />

      <div className="h-[300px] w-full mb-4">
        <div className="font-medium my-4">Likelihood Analysis</div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={100} height={100} data={analysis?.likelihood}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis
              label={{
                value: 'Value',
                angle: -90,
                position: 'insideLeft',
                offset: -10,
                style: { textAnchor: 'middle' },
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="value" stackId="a" fill="#8884d8" name="Likelihood"></Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
