import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { TranscriptLoader } from './components/loaders';
import { convertBlobToBase64, convertDeepgramToFlat, prepareTextFileContent } from '@utils/deepgram.utils';
import { SentenceNode } from 'src/typings/deepgram.types';
import { FileNode } from 'src/typings/googleDrive.types';
import { GoogleAPIs } from 'src/api';
import { EditTranscript } from './components/transcript';
import { XCircleIcon } from '@heroicons/react/solid';

import toast from 'react-hot-toast';
import axios from 'axios';

const DEEPGRAM_PROXY_URL = 'https://2rw5rl2bfg.execute-api.ap-south-1.amazonaws.com/default/deepgram-proxy';

type Props = {
  blob: Blob;
  subTargetFolder?: FileNode | null;
  transcriptFile?: FileNode | undefined;
  onUpdateFiles: () => void;
};

export default function TranscriptManager({ onUpdateFiles, blob, subTargetFolder, transcriptFile }: Props) {
  const [isTranscriptLoading, setIsTranscriptLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileUpdating, setIsFileUpdating] = useState<boolean>(false);
  const [generateTSError, setGenerateTSError] = useState<boolean>(false);
  const [showEditPopup, toggleEditPopup] = useState<boolean>(false);
  const [transcriptData, setTranscriptData] = useState<SentenceNode[]>([]);

  const generateDeepgramTranscript = async (blob: Blob) => {
    try {
      setIsTranscriptLoading(true);
      setGenerateTSError(false);
      const audioBase64: string = await convertBlobToBase64(blob);
      const token = localStorage.getItem('dg');
      const payload = {
        audio_base64: audioBase64,
        deepgram_api_key: token,
      };
      const res = await axios.post(DEEPGRAM_PROXY_URL, payload, {
        headers: { Accept: '*/*', 'content-type': 'text/plain;charset=UTF-8' },
      });
      const deepgramData = convertDeepgramToFlat(res.data);
      setTranscriptData(deepgramData);
    } catch (error) {
      console.error(error);
      toast.error('Failed to generate deepgram transcript!');
      setGenerateTSError(true);
    } finally {
      setIsTranscriptLoading(false);
    }
  };

  const fetchFileContent = async (fileId: FileNode['id']) => {
    try {
      setIsLoading(true);
      const res = await GoogleAPIs.fetchFileContent(fileId);
      setTranscriptData(res.messages);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateTranscriptData = async (newTranscript: SentenceNode[]) => {
    try {
      setIsFileUpdating(true);
      setTranscriptData([...newTranscript]);

      const fileContent = prepareTextFileContent(newTranscript);
      const blob = new Blob([fileContent], { type: 'text/plain' });

      // Create metadata for the file
      const fileName = 'transcript';
      let metadata = {
        name: `${fileName}.json`,
        mimeType: 'text/plain',
      };

      // Form the request body
      const formData = new FormData();

      if (transcriptFile) {
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', blob);

        // API to update existing file
        await GoogleAPIs.updateFile(transcriptFile.id, formData);
        toast.success('Text file was updated successfully!');
      } else if (subTargetFolder) {
        metadata = { ...metadata, parents: [subTargetFolder.id] };
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', blob);

        // API to create new file
        await GoogleAPIs.uploadFile(formData);
        toast.success('Text file was uploaded successfully!');
      }
      setGenerateTSError(false);
      onUpdateFiles();
    } catch (error) {
      console.error('Something went wrong ::', error);
    } finally {
      setIsFileUpdating(false);
    }
  };

  useEffect(() => {
    if (transcriptFile) {
      fetchFileContent(transcriptFile.id);
    }
  }, [transcriptFile]);

  const isDataLoading = isTranscriptLoading || isLoading;

  return (
    <>
      <div className="mt-4">
        {isDataLoading && (
          <div className="flex flex-col gap-4">
            <div>Loading pls wait....</div>
            <TranscriptLoader />
          </div>
        )}
        {!isDataLoading && !generateTSError && (!transcriptData || transcriptData.length === 0) && (
          <div className="flex flex-col gap-4 justify-center items-start py-10 mt-4">
            <div>No transcript found</div>
            <PrimaryButton propClass="w-44" onClick={() => generateDeepgramTranscript(blob)}>
              Generate Transcript
            </PrimaryButton>
          </div>
        )}
        {!isDataLoading && generateTSError && (
          <div className="flex flex-col justify-start items-center gap-4 border rounded-xl shadow-lg p-4">
            <div className="flex justify-start items-center gap-4">
              <XCircleIcon className="w-5 h-5 text-red-600" />
              <div className="font-medium">Something went wrong while generating transcript!</div>
            </div>

            <PrimaryButton propClass="w-60" onClick={() => toggleEditPopup(true)}>
              Create Transcript Manually
            </PrimaryButton>
          </div>
        )}

        {!isDataLoading && transcriptData.length > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <div className="font-medium text-lg">Generated Transcript:</div>
              <div className="flex justify-end items-center gap-x-2">
                <SecondaryButton disabled={isFileUpdating} onClick={() => toggleEditPopup(true)}>
                  Edit
                </SecondaryButton>
                <PrimaryButton
                  disabled={isFileUpdating}
                  propClass="w-20"
                  onClick={() => onUpdateTranscriptData(transcriptData)}
                >
                  Upload
                </PrimaryButton>
              </div>
            </div>
            <div className="flex flex-col gap-2 pb-40 ">
              {transcriptData.map((sentence, itr) => {
                return (
                  <div key={`para-${itr}`} className="flex justify-start items-start gap-x-2">
                    <div className="font-medium">{sentence.role}:</div>
                    <div>{sentence.content}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {transcriptData && (
        <EditTranscript
          showModal={showEditPopup}
          blob={blob}
          onClose={() => toggleEditPopup(false)}
          transcriptData={transcriptData}
          onSave={onUpdateTranscriptData}
        />
      )}
    </>
  );
}
