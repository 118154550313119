import { AudioBar } from '@components/AudioBar';
import { useEffect, useRef, useState } from 'react';
import { GoogleAPIs } from 'src/api';
import { FileNode } from 'src/typings/googleDrive.types';

import { prepareMetadataJson } from '@utils/deepgram.utils';
import toast from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import TranscriptManager from './TranscriptManager';
import { CallMetadata } from './components/metadata';

const tabs = [
  { id: 0, name: 'Transcript' },
  { id: 1, name: 'Call Metadata' },
];

type Props = {
  selectedFile: FileNode;
  sourceFolder: string;
  targetFolder: string;
};

export default function FileDetailsManager({ selectedFile, targetFolder }: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [subTargetFolder, setSubTargetFolder] = useState<FileNode | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetFiles, setTargetFiles] = useState<FileNode[]>([]);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);

  const tabNodeRefArr = useRef<HTMLDivElement[]>([]);

  const accessToken = localStorage.getItem('at');

  const fetchFileContent = async (fileId: FileNode['id']) => {
    setIsLoading(true);
    const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setFileBlob(blob);
    setAudioUrl(url);

    //Axios - @TODO

    // const response = await GoogleAPIs.fetchFileContent(fileId);
    // const audioBlob = new Blob([response], { type: 'audio/mpeg' }); // Adjust the MIME type if needed
    // const audioBlobUrl = URL.createObjectURL(audioBlob);
    // setAudioUrl(audioBlobUrl);
    // setTranscriptData(data);
    // setIsLoading(false);
    if (selectedFile?.mimeType === 'text/plain') return;

    const targetFolderFiles = await GoogleAPIs.fetchDriveFolder(targetFolder);
    const index = targetFolderFiles.files.findIndex((i) => i.name === selectedFile?.id);

    if (index === -1) {
      await GoogleAPIs.createNewFolder(selectedFile.id, [targetFolder]);
    } else {
      const item = targetFolderFiles.files[index];
      const data = await GoogleAPIs.fetchDriveFolder(item.id);
      setSubTargetFolder(item);
      setTargetFiles(data.files);
    }
    setIsLoading(false);
  };

  const onUpdateFiles = async () => {
    const targetFolderFiles = await GoogleAPIs.fetchDriveFolder(targetFolder);
    const index = targetFolderFiles.files.findIndex((i) => i.name === selectedFile?.id);
    if (index === -1) {
      //const res = await GoogleAPIs.createNewFolder(selectedFile.id, [targetFolder]);
    } else {
      const item = targetFolderFiles.files[index];
      const data = await GoogleAPIs.fetchDriveFolder(item.id);
      setSubTargetFolder(item);
      setTargetFiles(data.files);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      fetchFileContent(selectedFile?.id);
    }
  }, [selectedFile]);

  const metadataFile = targetFiles.find((i) => i.name === 'metadata.json');
  const transcriptFile = targetFiles.find((i) => i.name === 'transcript.json');
  const left =
    tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().x -
      tabNodeRefArr.current[0]?.getBoundingClientRect().x || 0;

  const width = tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().width;

  return (
    <div className="col-span-8 flex flex-col gap-3 p-2 h-full pb-40 overflow-y-auto">
      <div className="font-medium text-lg">{selectedFile?.name}</div>
      <div>
        Type : <span className="font-medium">{selectedFile.mimeType}</span>
      </div>
      {isLoading ? (
        <div>Fetching recoding....</div>
      ) : (
        audioUrl && (
          <div className="flex flex-col gap-2">
            <div>Recording:</div>
            <AudioBar audioURL={audioUrl} />
          </div>
        )
      )}
      <hr />
      {isLoading ? (
        <div>Fetching content......</div>
      ) : (
        <div className="w-full h-full">
          <div className="flex justify-between items-center">
            <div className="flex justify-start items-center gap-x-2 relative w-full">
              {tabs?.map((tab, index: number) => {
                const isSelected = selectedIndex === index;
                return (
                  <div
                    key={`apiResponse-${index}`}
                    className={twMerge(`cursor-pointer p-2 font-medium`, isSelected ? 'py-2' : '')}
                    ref={(element: HTMLDivElement) => (tabNodeRefArr.current[index] = element)}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                  >
                    {tab.name}
                  </div>
                );
              })}
              <div
                className="h-1 bg-primaryButton absolute -bottom-1 left-0 rounded-lg transition-[left] ease-in-out duration-200"
                style={{ left, width: width || '100px' }}
              ></div>
            </div>
          </div>
          {selectedIndex === 0 && (
            <TranscriptManager
              blob={fileBlob}
              transcriptFile={transcriptFile}
              subTargetFolder={subTargetFolder}
              onUpdateFiles={onUpdateFiles}
            />
          )}
          {selectedIndex === 1 && (
            <div className="mt-4">
              <CallMetadata
                metadataFile={metadataFile}
                subTargetFolder={subTargetFolder}
                onUpdateFiles={onUpdateFiles}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
