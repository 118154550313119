import { TopNavbar } from '@components/TopNavbar';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CoreAPIs } from 'src/api';
import { ScheduledCallLogDetails } from 'src/container/ScheduledCallLogDetails';
import { BorrowerCallNode, BorrowerDetailNode, BorrowerNode, CallLog } from 'src/typings/call.types';

type Props = {};

export default function ScheduledCallDetailsPage({}: Props) {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const [borrowerDetails, setBorrowerDetails] = useState<BorrowerDetailNode | null>(null);

  const getBorrowerDetails = async (borrowerCallsId: CallLog['borrowercalls_id']) => {
    const res = await CoreAPIs.getScheduledCallInfo(borrowerCallsId);
    setBorrowerDetails(res);
    return res;
  };

  useEffect(() => {
    if (id) {
      getBorrowerDetails(id);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen">
      <TopNavbar showBackButton />
      <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]">
        {borrowerDetails ? <ScheduledCallLogDetails borrowerDetails={borrowerDetails} /> : null}
      </div>
    </div>
  );
}
