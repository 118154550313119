import { Participants, SentenceNode, STTNode } from 'src/typings/deepgram.types';

export const getParticipantName = (participant: Participants) => {
  return Participants[participant];
};

export const prepareTextFileName = (audioFileName: string) => {
  return audioFileName.split('.mp3')[0];
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const d = reader.result.split(',')[1];
      resolve(d);
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};

export const prepareMetadataJson = (): string => {
  let metadata = {
    name: '',
    due_amount: null,
    total_amount: null,
    due_date: null,
    due_days: null,
    intent: null,
    agent_name: '',
  };
  return JSON.stringify(metadata);
};

export const convertDeepgramToFlat = (data: STTNode): SentenceNode[] => {
  let messages: SentenceNode[] = [];

  data.results.channels.forEach((channel) => {
    channel.alternatives.forEach((alt) => {
      alt.paragraphs.paragraphs.forEach((para) => {
        para.sentences.forEach((sentence) => {
          let newSentence: SentenceNode = {
            role: getParticipantName(para.speaker),
            content: sentence.text,
          };
          messages.push(newSentence);
        });
      });
    });
  });

  return messages;
};

export const prepareTextFileContent = (data: SentenceNode[]): string => {
  let text = { messages: data };
  return JSON.stringify(text);
};

export const prepareMetadataFileContent = (data: Object): string => {
  return JSON.stringify(data);
};

export const getParticipantsArray = (): { id: number; name: string }[] => {
  const result: { id: number; name: string }[] = [];

  // Iterate over enum keys
  for (const key in Participants) {
    if (isNaN(Number(key))) {
      // Filter out numeric keys
      const id = Participants[key as keyof typeof Participants];
      result.push({ id, name: key });
    }
  }

  return result;
};
