/* eslint-disable default-case */
/* eslint-disable no-useless-catch */
import { AxiosResponse } from 'axios';
import { createAxiosClient } from './axiosClient';
import { Client, clientFactory } from './clientFactory';
import ms from 'ms';

const DEFAULT_API_TIMEOUT = ms('100sec');

export const networkHandler = async (
  method: string,
  url: string,
  body: any,
  client: Client,
  timeout = DEFAULT_API_TIMEOUT,
) => {
  try {
    const axiosClient = createAxiosClient(client, timeout);

    /* Refetch access-token if not present before making any API call */

    // Create a client instance using the client factory
    const clientInstance = clientFactory.createClient(client);

    // Retrieve the access token from the client instance
    const token = clientInstance.getAccessToken();

    // Retrieve the refresh token from the client instance
    const refreshToken = clientInstance.getRefreshToken();

    // Check if there is no access token, but there is a refresh token,
    // and the URL is not the token refresh endpoint
    if (!token && refreshToken && url !== '/api/token/refresh/') {
      // Attempt to fetch a new access token using the refresh token
      await clientInstance.fetchNewAccessToken(refreshToken);
    }

    switch (method) {
      case 'get': {
        const response: AxiosResponse = await axiosClient.get(`${url}`);
        return response?.data;
      }
      case 'post': {
        const response: AxiosResponse = await axiosClient.post(`${url}`, body);
        return response?.data;
      }
      case 'put': {
        const response: AxiosResponse = await axiosClient.put(`${url}`, body);
        return response?.data;
      }
      case 'patch': {
        const response: AxiosResponse = await axiosClient.patch(`${url}`, body);
        return response?.data;
      }
      case 'delete': {
        const response: AxiosResponse = await axiosClient.delete(`${url}`);
        return response?.data;
      }
      case 'default':
        return new Error('Invalid method passed');
    }
  } catch (error) {
    throw error;
  }
  return undefined;
};
