import { useEffect, useRef, useState } from 'react';
import { useGetCallLogs } from 'src/hooks/apis/calls';
import { SecondaryButton } from '@components/Buttons';
import { useNavigate } from 'react-router-dom';
import { getUserIntentReason } from '../../utils/call.utils';
import moment from 'moment';
import { DashboardLoader } from '@components/Loader';
import { CallLog, CallType } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';
import { Checkbox, Tooltip } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import { UserIntentFilter } from '@components/UserIntentFilter';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';
import { UserPhoneFilter } from '@components/UserPhoneFilter';
import get from 'lodash/get';
import { UserDurationFilter } from '@components/UserDurationFilter';
import { queryClient } from 'src/queryClient';
import { callLogs } from 'src/queryClient/queryKeys';
import { InlineAudioBar } from '@components/InlineAudioBar';
import { TextField } from '@components/TextField';
import { isNumber } from 'lodash';
import toast from 'react-hot-toast';
import { openNewTab } from '@utils/window.utils';
import { UserDateRangeFilter } from '@components/UserDateRangeFilter';

type Props = {
  company: CompanyNode | null;
  onScrollToTop: () => void;
};

const enableRowSelection = false;
const size = 50;

export default function ActualCalls({ company, onScrollToTop }: Props) {
  const prevPage = getDataFromStorage('page');
  const prevParams = getDataFromStorage('params');
  const navigateTo = useNavigate();
  const [selectedItems, setSelectedItems] = useState<CallLog[]>([]);
  const [params, setParams] = useState<any>({ ...prevParams } || {});
  const [page, setPage] = useState<number>(prevPage || 1);
  const [pageValue, setPageValue] = useState<number>(prevPage || 1);

  const [maxPage, setMaxPage] = useState<number>(0);
  const iconButtonRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useGetCallLogs({
    page,
    size,
    callType: CallType.REGULAR,
    companyId: company?.id === 'all' ? '' : company?.id,
    params: { ...params },
  });

  const handleFilterChange = (filters: any) => {
    setDataInStorage('params', { ...params, ...filters });
    queryClient.invalidateQueries([callLogs, CallType.REGULAR, page, company?.id, { ...params, ...filters }]);
    setParams({ ...params, ...filters });
    updatePage(1);
  };

  const actualCalls = data?.items.sort((a, b) => moment(b.call_start).diff(moment(a.call_start)));

  const onRowPress = (id: number | string) => {
    openNewTab(`/dashboard/${id}`);
    //  navigateTo(`/dashboard/${id}`);
  };

  const addItem = (log: CallLog) => {
    setSelectedItems([...selectedItems, log]);
  };

  const removeItem = (log: CallLog) => {
    setSelectedItems(selectedItems.filter((i) => i.id !== log.id));
  };

  const updatePage = (newPageNumber: number) => {
    setPage(newPageNumber);
    setPageValue(newPageNumber);
    setDataInStorage('page', newPageNumber);
    onScrollToTop();
  };

  const handlePageInputBlur = () => {
    try {
      const val = parseInt(pageValue, 10);
      if (!isNumber(val) || !(pageValue > 0 && pageValue <= maxPage)) {
        toast.error(`Please enter a valid page number 1 and ${maxPage}`);
        updatePage(1);
      } else {
        updatePage(val);
      }
    } catch (error) {
      toast.error(`Please enter a valid page number 1 and ${maxPage}`);
      updatePage(1);
    }
  };

  const onSelectRow = (e, log: CallLog) => {
    e.preventDefault();
    e.stopPropagation();

    const index = selectedItems?.findIndex((l) => l.id === log.id);
    if (index === -1) {
      addItem(log);
    } else {
      removeItem(log);
    }
  };

  useEffect(() => {
    if (data) {
      setMaxPage(data.pages);
    }
  }, [data]);

  if (isLoading) {
    return <DashboardLoader />;
  }

  return (
    <div className="w-full flex flex-col justify-start items-center gap-4">
      <div className="w-full flex justify-start items-center">
        <div className="text-left text-sm font-medium mt-5">{`Results (${data?.total})`}</div>
      </div>
      <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
        <thead className="border p-2 bg-gray-100 w-full">
          <tr className="border p-2 w-full">
            {enableRowSelection && <td className="border p-2 text-sm font-bold text-blue-950"></td>}
            <td className="border p-2 text-sm font-bold text-blue-950">#</td>
            {/* <td className="border p-2 text-sm font-bold text-blue-950">Call ID</td> */}
            <td className="border p-2 text-sm font-bold text-blue-950">Borrower Name</td>
            <td className="border p-2 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Call To</div>
                <UserPhoneFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'phone_numbers', null)}
                />
              </div>
            </td>
            <td className="border p-2 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Duration</div>
                <UserDurationFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={get(prevParams, 'duration', null)}
                />
              </div>
            </td>
            <td className="border p-2 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>User Intent</div>
                <UserIntentFilter onFilterChange={handleFilterChange} prevFilter={get(prevParams, 'intent', [])} />
              </div>
            </td>
            <td className="border p-2 text-sm font-bold text-blue-950">
              <div className="flex justify-between items-center relative">
                <div ref={iconButtonRef}>Call Started at</div>
                <UserDateRangeFilter
                  onFilterChange={handleFilterChange}
                  prevFilter={{
                    start_date: get(prevParams, 'start_date', null),
                    end_date: get(prevParams, 'end_date', null),
                  }}
                />
              </div>
            </td>
            {/* <td className="border p-2 text-sm font-bold text-blue-950">Call Ended at</td> */}
            <td className="border p-2 text-sm font-bold text-blue-950">Call Recording</td>
          </tr>
        </thead>
        <tbody>
          {actualCalls?.length === 0 ? (
            <tr className="w-full h-20 p-10 flex justify-center items-center font-medium text-sm">
              No data found, Please change the page to get more data
            </tr>
          ) : (
            actualCalls?.map((log, index) => {
              const isSelected = selectedItems.findIndex((i) => i.id === log.id) !== -1;
              return (
                <tr
                  className={twMerge(
                    'text-sm p-2 hover:bg-gray-200 cursor-pointer transition-all ease-in-out w-full',
                    isSelected ? 'bg-blue-100' : '',
                  )}
                  onClick={() => onRowPress(log.call_sid)}
                >
                  {enableRowSelection && (
                    <td className="border p-3">
                      <Checkbox checked={isSelected} onClick={(e) => onSelectRow(e, log)} />
                    </td>
                  )}
                  <td className="border p-3">{(page - 1) * size + index + 1}</td>
                  {/* <td className="border p-3">{log.call_sid}</td> */}
                  <td className="border p-3">{log.borrower_name}</td>
                  <td className="border p-3">{log.call_to}</td>
                  <td className="border p-3">{log.duration}s</td>
                  <td className="border p-3">{getUserIntentReason(log.intent)}</td>
                  <td className="border p-3">{moment(log.call_start).format('lll')}</td>
                  {/* <td className="border p-3">{moment(log.call_end).format('lll')}</td> */}
                  <td className="border p-3">
                    <InlineAudioBar callId={log.call_sid} />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="flex justify-start items-center gap-x-2">
        <SecondaryButton className="w-10" onClick={() => updatePage(page - 1)} disabled={page === 1}>
          Prev
        </SecondaryButton>
        {/* <div className="border rounded-lg px-4 py-2 font-semibold text-blue-950">{page}</div> */}
        <Tooltip title="Enter page number you want to see ">
          <div className="min-w-20">
            <TextField
              type="number"
              value={pageValue}
              onChange={(v) => setPageValue(v.target.value)}
              onBlur={handlePageInputBlur}
            />
          </div>
        </Tooltip>

        <SecondaryButton className="w-10" onClick={() => updatePage(page + 1)} disabled={page === maxPage}>
          Next
        </SecondaryButton>
      </div>
    </div>
  );
}
