import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { pickStats } from 'src/queryClient/queryKeys';
import { PickStatsNode, StatsPayload } from 'src/typings/stats.types';

const getPickStats = async (params: StatsPayload): Promise<PickStatsNode[]> => {
  const data = await CoreAPIs.getPickStats(params);
  return data.pick_stats;
};

export const useGetPickStats = (payload: StatsPayload) => {
  const result = useQuery<PickStatsNode[], Error>(
    [
      pickStats,
      payload.metric_type,
      payload.company_id,
      payload.start_date,
      payload?.end_date || 'NA',
      payload?.telephony || 'COMBINED',
      payload?.time_of_day || 'NA',
    ],
    () => getPickStats(payload),
    {
      staleTime: ms('20min'),
      ///enabled: enabled && Boolean(callId),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  return result;
};
