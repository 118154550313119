import { SkeletonLoader } from '@components/SkeletonLoader';

export default function CallDetailsLoader() {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-start items-start gap-x-4">
        <SkeletonLoader width="100%" height={200} />
        <SkeletonLoader width="100%" height={200} />
        <SkeletonLoader width="100%" height={200} />
      </div>
      <div className="flex justify-start items-center gap-x-4">
        <SkeletonLoader width="80%" height={100} />
      </div>
      <div className="flex justify-start items-start gap-x-4">
        <SkeletonLoader width="100%" height={300} />
        <SkeletonLoader width="100%" height={300} />
      </div>
    </div>
  );
}
