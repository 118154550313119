import { useEffect, useState } from 'react';
import { useGetCallLogs } from 'src/hooks/apis/calls';
import { SecondaryButton } from '@components/Buttons';
import { useNavigate } from 'react-router-dom';
import { DashboardLoader } from '@components/Loader';
import { CallType } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';
import moment from 'moment';
import { getDataFromStorage, setDataInStorage } from '@utils/storageUtils';

type Props = {
  company: CompanyNode | null;
  onScrollToTop: () => void;
};
const size = 50;

export default function GarbageCalls({ company, onScrollToTop }: Props) {
  const navigateTo = useNavigate();
  const prevPage = getDataFromStorage('page');

  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(0);
  const { data, isLoading } = useGetCallLogs({
    page,
    size,
    callType: CallType.GARBAGE,
    companyId: company?.id === 'all' ? '' : company?.id,
  });

  const onRowPress = (id: number | string) => {
    navigateTo(`/dashboard/${id}`);
  };

  const garbageCalls = data?.items;

  useEffect(() => {
    if (data) {
      setMaxPage(data.pages);
    }
  }, [data]);

  if (isLoading) {
    return <DashboardLoader />;
  }

  return (
    <div className="w-full flex flex-col justify-start items-center gap-4">
      <div className="w-full flex justify-start items-center">
        <div className="text-left text-sm font-medium mt-5">{`Results (${data?.total})`}</div>
      </div>
      <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
        <thead className="border p-2 bg-gray-100 w-full">
          <tr className="border p-2 w-full">
            <td className="border p-2 text-sm font-bold text-blue-950">#</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call ID</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Borrower Name</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Call To</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Previous Call ID</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Knowl Requested time</td>
            <td className="border p-2 text-sm font-bold text-blue-950">Submit Time</td>
          </tr>
        </thead>
        <tbody>
          {garbageCalls?.length === 0 ? (
            <tr className="w-full h-10 flex justify-center items-center font-medium text-sm">No data found</tr>
          ) : (
            garbageCalls?.map((log, index) => {
              return (
                <tr className="text-sm p-2 hover:bg-gray-200 cursor-pointer" onClick={() => onRowPress(log.call_sid)}>
                  <td className="border p-3">{(page - 1) * size + index + 1}</td>
                  <td className="border p-3">{log.call_sid}</td>
                  <td className="border p-3">{log.borrower_name}</td>
                  <td className="border p-3">{log.call_to || log.borrower_phone_number}</td>
                  <td className="border p-3">{log.previous_call_sid}</td>
                  <td className="border p-3">{moment.utc(log.knowl_requested_time).local().format('lll')}</td>
                  <td className="border p-3">{moment.utc(log.call_submit_time).local().format('lll')}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <div className="flex justify-start items-center gap-x-2">
        <SecondaryButton
          className="w-10"
          onClick={() => {
            setPage(page - 1);
            setDataInStorage('page', page - 1);
            onScrollToTop();
          }}
          disabled={page === 1}
        >
          Prev
        </SecondaryButton>
        <div className="border rounded-lg px-4 py-2 font-semibold text-blue-950">{page}</div>
        <SecondaryButton
          className="w-10"
          onClick={() => {
            setPage(page + 1);
            setDataInStorage('page', page + 1);
            onScrollToTop();
          }}
          disabled={page === maxPage}
        >
          Next
        </SecondaryButton>
      </div>
    </div>
  );
}
