import { motion, AnimatePresence } from 'framer-motion';
import { useRef } from 'react';
import { useOnClickOutside } from 'src/hooks/utils';
import { twMerge } from 'tailwind-merge';

export enum PopoverPosition {
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
}

const positionConfig = {
  [PopoverPosition.BottomRight]: {
    pointerParent: 'top-[26px] right-[3px]',
    pointer: 'rotate-45 transform origin-bottom-left border-b-0',
    content: 'right-[-14px] top-[41px]',
  },
  [PopoverPosition.BottomLeft]: {
    pointerParent: 'top-[22px] left-[3px]',
    pointer: 'rotate-45 transform origin-bottom-left border-b-0',
    content: 'left-[-14px] top-[37px]',
  },
  [PopoverPosition.TopRight]: {
    pointerParent: 'top-[-13px] right-[3px]',
    pointer: ' transform origin-bottom-right rotate-45 border-l-0 border-t-0',
    content: 'right-[-20px] bottom-[30px]',
  },
  [PopoverPosition.TopLeft]: {
    pointerParent: 'top-[-10px] left-[-5px]',
    pointer: 'rotate-45 transform origin-bottom-right border-t-0 border-l-0',
    content: `left-[-20px] bottom-[25px]`,
  },
};

interface IProps {
  showPopover: boolean;
  children: JSX.Element | JSX.Element[] | null;
  contentPosition: PopoverPosition;
  contentPropClass?: string;
  onClose: () => void;
  anchorEl?: any;
}

export default function Popover(props: IProps) {
  const { showPopover, children, contentPosition, contentPropClass, onClose, anchorEl } = props;
  const popoverRef = useRef<HTMLDivElement>(null);

  const { pointerParent, pointer, content } = positionConfig[contentPosition];

  useOnClickOutside(popoverRef, () => onClose());

  return (
    <AnimatePresence>
      {showPopover ? (
        <>
          <motion.div
            initial={{ y: -50, opacity: 0.5 }}
            exit={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, type: 'spring' }}
            className={twMerge('w-6 overflow-hidden inline-block absolute z-[1001]', pointerParent)}
          >
            <div className={twMerge('bg-popupBgColor h-4 w-4 shadow-lg border border-borderColor ', pointer)} />
          </motion.div>
          <motion.div
            key="filterContainer"
            initial={{ y: -50, opacity: 0.5 }}
            exit={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3, type: 'spring' }}
            ref={popoverRef}
            className={twMerge(
              'bg-popupBgColor border border-borderColor rounded-2xl min-w-[200px] p-2 shadow-lg absolute z-[100]',
              content,
              contentPropClass,
            )}
          >
            {children}
          </motion.div>
        </>
      ) : null}
    </AnimatePresence>
  );
}
