import { useMutation, useQueryClient } from 'react-query';
import { CoreAPIs } from '../../../api';
import { CallLog, CallLogDetails, CallType } from 'src/typings/call.types';
import { callLogs } from 'src/queryClient/queryKeys';
import { CompanyNode } from 'src/typings/user.types';

type Params = {
  callId: CallLog['id'];
  page: number;
  size: number;
  callType: CallType;
  companyId?: CompanyNode['id'];
};

const deleteScheduledCall = async (params: Params) => {
  const data = await CoreAPIs.deleteScheduledCall(params.callId);
  return data;
};

export const useDeleteScheduledCall = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<CallLogDetails, Error, Params, unknown>(deleteScheduledCall, {
    onSuccess: (responseData, params) => {
      queryClient.invalidateQueries([callLogs, params.callType, params.page, params.companyId]);
    },
  });
  return mutation;
};
