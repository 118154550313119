import { CompanyNode, CompanyUser, UserNode } from 'src/typings/user.types';
// import { getCompanyUsers } from './storageUtils';

export const ANONYMOUS_USER_EMAIL = 'AnonymousUser@localhost.com';
export const getInitials = (firstName?: string, lastName?: string): string => {
  if (!firstName && !lastName) return 'NA';
  let str = '';
  if (firstName) str = str + firstName.charAt(0);
  if (lastName) str = str + lastName.charAt(0);
  return str.toUpperCase();
};

export const getCompleteName = (firstName: string, lastName: string, isActive: boolean = true): string => {
  if (!firstName && !lastName) return '-';
  return `${firstName} ${lastName} ${!isActive ? '(De-Activated)' : ''}`;
};

export const getWorkspaceNameInitials = (workspaceName: CompanyNode['name']) => {
  const workspaceArray = workspaceName.split(' ');
  const firstName = workspaceArray[0] ? workspaceArray[0] : '';
  const lastName = workspaceArray[1] ? workspaceArray[1] : '';
  return { firstName, lastName };
};

export const colorMap = ['#FFF5D1', '#D1FFF9', '#D1E3FF', '#FFDFD1', '#FFE4FE', '#E8D1FF'];

export const assignColor = (index: number) => {
  const totalColors = colorMap.length - 1;
  return colorMap[index % totalColors];
};

const generateRandomColor = () => colorMap[Math.floor(Math.random() * (colorMap.length - 1))];

export const getUserAvatarColor = (user: UserNode | null) => {
  if (!user) return generateRandomColor();
  const { id: userId, first_name, last_name } = user;
  const companyUsers: CompanyUser[] = []; //getCompanyUsers();

  const index = companyUsers
    ? companyUsers?.findIndex(
        (i: CompanyUser) => i.id === userId || (i.first_name === first_name && i.last_name === last_name),
      )
    : -1;
  if (index !== -1) {
    return companyUsers[index].userColor;
  } else {
    return generateRandomColor();
  }
};

export const getUserNameWithCursorColor = (user: UserNode | undefined) => {
  const obj = { userName: ANONYMOUS_USER_EMAIL, color: '#FF6961' };

  if (user) {
    obj['userName'] = user.first_name;
    obj['color'] = getUserAvatarColor(user);
  }
  return obj;
};
