import { FileNode } from 'src/typings/googleDrive.types';
import { Client } from '../axios/clientFactory';
import { networkHandler } from '../axios/networkHandler';

export const fetchDriveFolder = async (folderId: string) => {
  try {
    const url = `/drive/v3/files?q='${folderId}' in parents and trashed = false&fields=files(id,name,mimeType)`;
    return await networkHandler('get', url, {}, Client.GOOGLE, 0);
  } catch (error) {
    console.error('ERROR: fetchDriveFolder', error);
    throw error;
  }
};

export const fetchFileContent = async (fileId: FileNode['id']) => {
  try {
    const url = `drive/v3/files/${fileId}?alt=media`;
    return await networkHandler('get', url, {}, Client.GOOGLE, 0);
  } catch (error) {
    console.error('ERROR: fetchFileContent', error);
    throw error;
  }
};

export const uploadFile = async (formData: FormData) => {
  try {
    const url = `upload/drive/v3/files?uploadType=multipart`;
    return await networkHandler('post', url, formData, Client.GOOGLE, 0);
  } catch (error) {
    console.error('ERROR: uploadFile', error);
    throw error;
  }
};

export const updateFile = async (fileId: FileNode['id'], formData: FormData) => {
  try {
    const url = `upload/drive/v3/files/${fileId}?uploadType=multipart`;
    return await networkHandler('patch', url, formData, Client.GOOGLE, 0);
  } catch (error) {
    console.error('ERROR: uploadFile', error);
    throw error;
  }
};

export const createNewFolder = async (newFolderName: string, parentFolderId: string[]) => {
  try {
    const payload = {
      name: newFolderName,
      mimeType: 'application/vnd.google-apps.folder', // Specifies that the file is a folder
      parents: parentFolderId, // Specifies the parent folder
    };
    const url = `drive/v3/files`;
    return await networkHandler('post', url, payload, Client.GOOGLE, 0);
  } catch (error) {
    console.error('ERROR: createNewFolder', error);
    throw error;
  }
};
