import { styled } from '@mui/material/styles';
import { Tooltip as MuiTooltip, TooltipProps, tooltipClasses } from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'bg-codeWrapperBgColor',
    border: 'none',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.72rem',
    color: 'text-textPrimary',
    backgroundColor: 'bg-codeWrapperBgColor',
    border: 'none',
    borderRadius: '0.2rem',
    fontWeight: 600,
    letterSpacing: '0.02rem',
  },
}));
