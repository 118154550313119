import { clearUserCookies } from '@utils/cookieUtils';
import { GOOGLE_API_BASE } from 'src/api/config';
import { EventEmitter } from '@utils/Events';

export class Google {
  getBaseUrl() {
    return GOOGLE_API_BASE;
  }

  getAccessToken() {
    const key = localStorage.getItem('at');
    return key;
  }

  getRefreshToken() {
    const key = localStorage.getItem('rt');
    return key;
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('at', accessToken);
    // setAccessToken(accessToken);
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('rt', refreshToken);
    // setRefreshToken(refreshToken);
  }

  removeAccessToken() {
    clearUserCookies();
  }

  removeRefreshToken() {
    clearUserCookies();
  }

  async fetchNewAccessToken(refreshToken: string) {
    try {
      const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

      if (refreshToken) {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            client_id: GOOGLE_CLIENT_ID,
            client_secret: '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',
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
          }),
        });

        const data = await response.json();
        const { access_token } = data;
        this.setAccessToken(access_token);
        refreshToken && this.setRefreshToken(refreshToken);
        // Update access and refresh token in context
        EventEmitter.emit('updateTokens', access_token, refreshToken);
        return;
      }
    } catch (error) {
      console.error('ERROR: fetchNewAccessToken', error);
      throw error;
    }
  }
}
