import { TopNavbar } from '@components/TopNavbar';
import { Navigate } from 'react-router-dom';
import { OpsManager } from 'src/container/OpsManager';

type Props = {};

export default function OpsManagementPage({}: Props) {
  const accessToken = localStorage.getItem('at');

  if (!accessToken) {
    return <Navigate to="/google-login" />;
  }

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <TopNavbar showBackButton pageTitle="Operations" />
      <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-8 py-4 md:py-10 max-h-screen bg-[#F8F8FA]">
        <OpsManager />
      </div>
    </div>
  );
}
