export const getEmoji = (text: string) => {
  switch (text) {
    case 'Anger':
      return '😡';
    case 'Determination':
      return '✊';
    case 'Calmness':
      return '😌';
    case 'Distress':
      return '😰';
    case 'Satisfaction':
      return '😌';
    case 'Enthusiasm':
      return '😃';
    default:
      return '';
  }
};
