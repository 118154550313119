import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { callLogs } from 'src/queryClient/queryKeys';
import { CallLogResponse } from 'src/api/services/coreAPI';
import { CallType } from 'src/typings/call.types';
import { CompanyNode } from 'src/typings/user.types';

type PaginationNode = {
  page: number;
  size: number;
  callType: CallType;
  companyId?: CompanyNode['id'];
  params?: {
    phone_numbers?: string[];
    intent?: number[];
    duration?: number;
    start_date?: string;
    end_date?: string;
  };
};

const getCallLogs = async (args: PaginationNode): Promise<CallLogResponse> => {
  const data = await CoreAPIs.getCallLogs({
    ...args,
    duration: args?.params?.duration,
    start_date: args?.params?.start_date,
    end_date: args?.params?.end_date,
  });
  return data;
};

export const useGetCallLogs = (args: PaginationNode) => {
  const result = useQuery<CallLogResponse, Error>(
    [callLogs, args.callType, args.page, args.companyId, { ...args.params }],
    () => getCallLogs(args),
    {
      staleTime: ms('5min'),
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      refetchInterval: ms('5min'),
    },
  );
  return result;
};
