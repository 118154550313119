import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { CenterModalWithHeader } from '@components/Modal/CenterModalWithHeader';
import { InputLabel, TextField } from '@components/TextField';
import { useState } from 'react';

type Props = {
  showModal: boolean;
  onClose: () => void;
};

export default function DeepgramKeyManager({ showModal, onClose }: Props) {
  const key = localStorage.getItem('dg');
  const [newKey, setNewKey] = useState<string>(key || '');

  const onSaveKey = () => {
    localStorage.setItem('dg', newKey);
    onClose();
  };

  return (
    <CenterModalWithHeader
      title="Add Deepgram Key"
      shouldClose
      isVisible={showModal}
      className="w-[400px]"
      onClose={onClose}
    >
      <div className="p-6 max-h-[650px] h-full overflow-y-scroll">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-1 w-full">
            <InputLabel label="Deepgram Key" />
            <TextField placeholder="Add deepgram key " value={newKey} onChange={(e) => setNewKey(e.target.value)} />
          </div>

          <div className="flex justify-end items-center mt-2 gap-x-4">
            <SecondaryButton propClass="w-20" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onSaveKey} disabled={!newKey} propClass="w-10">
              Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CenterModalWithHeader>
  );
}
