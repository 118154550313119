import { ArrowSmDownIcon, ArrowSmUpIcon, MenuAlt4Icon } from '@heroicons/react/solid';
import { twMerge } from 'tailwind-merge';

type Props = {
  type?: 'absolute' | 'percentage';
  today: number;
  yesterday: number;
};

export default function StatCompare({ today, yesterday, type = 'absolute' }: Props) {
  const change = today - yesterday;
  const diff = change > 0;

  const val = type === 'percentage' ? Math.abs(change).toFixed(2) : Math.abs(change);

  return (
    <div
      className={twMerge(
        change === 0 ? 'text-yellow-500' : diff ? 'text-green-500' : 'text-red-500',
        'flex justify-start items-center text-[10px]',
      )}
    >
      {change === 0 ? (
        <MenuAlt4Icon className="w-3 h-3 text-yellow-500" />
      ) : diff ? (
        <ArrowSmUpIcon className="w-4 h-4 text-green-500" />
      ) : (
        <ArrowSmDownIcon className="w-4 h-4 text-red-500" />
      )}
      {`(${val}${type === 'percentage' ? '%' : ''})`}
    </div>
  );
}
