import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { TextField } from '@components/TextField';
import { FilterIcon, XCircleIcon } from '@heroicons/react/outline';
import { IconButton, Popover } from '@mui/material';
import { useRef, useState } from 'react';

type Props = {
  onFilterChange: (filters: any) => void;
  prevFilter: string[];
};

export default function UserPhoneFilter({ onFilterChange, prevFilter }: Props) {
  const iconRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState<string[]>(prevFilter || []);
  const [showFilter, toggleShowFilter] = useState<boolean>(false);
  const [val, setVal] = useState<string>('');

  const onRemove = (val: string) => {
    const index = selectedFilters?.findIndex((l) => l === val);
    if (index !== -1) {
      let updatedFilters = [];
      updatedFilters = selectedFilters.filter((i) => i !== val);
      setSelectedFilters([...updatedFilters]);
      onFilterChange({
        phone_numbers: [...updatedFilters],
      });
    }
  };

  const onAddFilter = () => {
    setSelectedFilters([...selectedFilters, val]);
    onFilterChange({
      phone_numbers: [...selectedFilters, val],
    });
    setVal('');
  };

  const onClearFilter = () => {
    setSelectedFilters([]);
    onFilterChange({
      phone_numbers: [],
    });
    setVal('');
  };

  const handleChange = (val: string) => {
    setVal(val);
  };

  return (
    <>
      <div className="relative">
        <IconButton ref={iconRef} onClick={() => toggleShowFilter(!showFilter)}>
          <FilterIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>

        {selectedFilters.length > 0 && (
          <div className="absolute top-0 right-0 w-4 h-4 bg-red-500 rounded-full flex justify-center items-center text-white text-[10px]">
            {selectedFilters.length}
          </div>
        )}
      </div>

      <Popover
        open={showFilter}
        onClose={() => toggleShowFilter(false)}
        anchorEl={iconRef.current}
        anchorOrigin={{
          vertical: 50, // 50 pixels offset from the top of the page
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col gap-y-2 p-4 w-[250px]">
          <div className="flex flex-col gap-y-3 w-full overflow-auto">
            <div className="text-lg font-medium">Phone number</div>

            <TextField value={val} placeholder="Enter phone number.." onChange={(e) => handleChange(e.target.value)} />

            <div className="w-full flex justify-between items-center mt-2">
              <SecondaryButton disabled={selectedFilters.length === 0} propClass="w-20" onClick={onClearFilter}>
                Clear
              </SecondaryButton>
              <PrimaryButton disabled={val.length < 10} propClass="w-10" onClick={onAddFilter}>
                Apply
              </PrimaryButton>
            </div>
          </div>
          {selectedFilters.length > 0 && (
            <div className="flex flex-col gap-y-2 mt-2">
              <div className="text-sm">Added Phone numbers</div>
              <div className="flex flex-wrap items-center gap-2">
                {selectedFilters.map((phone) => {
                  return (
                    <div className="flex justify-between items-center pl-1 border rounded-2xl border-blue-200">
                      <div className="text-xs">{phone}</div>
                      <IconButton onClick={() => onRemove(phone)}>
                        <XCircleIcon className="w-5 h-5" />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}
