type LabelProps = {
  label: string;
  mandatory?: boolean;
};

export const InputLabel = ({ label, mandatory = false }: LabelProps) => {
  return (
    <div className="flex items-center mb-1.5">
      <p className="text-sm text-textPrimary font-medium truncate">
        {label}
        {mandatory && <sup className="text-red-400">*</sup>}
      </p>
    </div>
  );
};
