import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { phoneNumbers } from 'src/queryClient/queryKeys';
import { PhoneNumberNode } from 'src/typings/call.types';

const getPhones = async (): Promise<PhoneNumberNode[]> => {
  const data = await CoreAPIs.getPhoneNumbers();
  return data;
};

export const useGetPhoneNumbers = () => {
  const result = useQuery<PhoneNumberNode[], Error>([phoneNumbers], () => getPhones(), {
    staleTime: ms('100min'),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: ms('100min'),
  });
  return result;
};
