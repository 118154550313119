import { AudioBar } from '@components/AudioBar';
import { Transcript } from '@components/Transcript';
import { AnnotationIcon, MicrophoneIcon, ServerIcon } from '@heroicons/react/outline';
import { formatDuration, parseLatencyValues } from '@utils/call.utils';
import moment from 'moment';
import { CallLogDetails } from 'src/typings/call.types';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin: boolean;
};

export default function CallRecording({ callDetails, allowAdmin }: Props) {
  return (
    <div className="w-full flex flex-col gap-y-4">
      <div className="grid grid-flow-col grid-cols-12">
        <div className="flex flex-col gap-4 col-span-8 p-4 mr-4">
          <div className="flex flex-col justify-start items-start gap-4 p-2">
            <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
              <MicrophoneIcon className="w-4 h-4 text-primaryButton" /> Call Recording
            </div>
            <AudioBar audioURL={callDetails.recording} />
          </div>
        </div>
        <div className="col-span-4">
          <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
            <ServerIcon className="w-4 h-4 text-primaryButton" /> Call Overview
          </div>
          <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Duration</td>
              <td className="border p-2 text-sm font-medium">{formatDuration(callDetails.duration)}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Call Started at</td>
              <td className="border p-2 text-sm font-medium">{moment(callDetails?.call_start).format('lll')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Call Ended at</td>
              <td className="border p-2 text-sm font-medium">{moment(callDetails?.call_end).format('lll')}</td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Latency</td>
              <td className="border p-2 text-sm font-medium">
                {formatDuration(parseLatencyValues(callDetails?.latency))}
              </td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium">Disconnected by</td>
              <td className="border p-2 text-sm font-medium">{callDetails?.disconnected_by ? 'User' : 'Agent'}</td>
            </tr>
          </table>
        </div>
      </div>
      <hr className="w-full h-[1px] bg-gray-200 mt-4" />
      <div className="flex flex-col justify-start items-start gap-4 p-2">
        <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
          <AnnotationIcon className="w-4 h-4 text-primaryButton" /> Call Transcript
        </div>
        <Transcript url={callDetails.transcript} showStats={allowAdmin} />
      </div>
    </div>
  );
}
