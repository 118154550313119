import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { callStats } from 'src/queryClient/queryKeys';
import { CallStatsNode, StatsPayload } from 'src/typings/stats.types';

const getCallStats = async (params: StatsPayload): Promise<CallStatsNode[]> => {
  const data = await CoreAPIs.getCallStats(params);
  return data.call_stats;
};

export const useGetCallStats = (payload: StatsPayload) => {
  const result = useQuery<CallStatsNode[], Error>(
    [
      callStats,
      payload.metric_type,
      payload.company_id,
      payload.start_date,
      payload?.end_date,
      payload?.telephony || 'COMBINED',
    ],
    () => getCallStats(payload),
    {
      staleTime: ms('20min'),
      ///enabled: enabled && Boolean(callId),
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
  return result;
};
