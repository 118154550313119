import ms from 'ms';
import { useQuery } from 'react-query';
import { CoreAPIs } from '../../../api';
import { companies } from 'src/queryClient/queryKeys';
import { CompanyNode } from 'src/typings/user.types';

const getCompanyList = async (): Promise<CompanyNode[]> => {
  const data = await CoreAPIs.getCompanyList();
  return data?.items || [];
};

export const useGetCompanyList = ({ enabled = true }: { enabled?: boolean }) => {
  const result = useQuery<CompanyNode[], Error>([companies], () => getCompanyList(), {
    staleTime: ms('120min'),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: ms('120min'),
    enabled,
  });
  return result;
};
