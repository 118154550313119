import { networkHandler } from '../axios/networkHandler';
import { Client } from '../axios/clientFactory';
import { SocialLogin, UserData, UserLogin } from 'src/typings/user.types';

export const loginUser = async (payload: UserLogin) => {
  try {
    const url = `/api/token/`;
    return await networkHandler('post', url, payload, Client.CORE);
  } catch (error) {
    console.error('ERROR: loginUser', error);
    throw error;
  }
};

export const refreshToken = async (refresh: string) => {
  try {
    const url = `/api/token/refresh/`;
    const payload = { refresh };
    return await networkHandler('post', url, payload, Client.CORE);
  } catch (error) {
    console.error('ERROR: refreshToken', error);
    throw error;
  }
};

export const socialLogin = async (payload: SocialLogin): Promise<UserData> => {
  try {
    const url = `/api/social-login/`;
    return await networkHandler('post', url, payload, Client.CORE);
  } catch (error) {
    console.error('ERROR: socialLogin', error);
    throw error;
  }
};
