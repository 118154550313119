import * as Actions from './actions';
import { CompanyNode } from 'src/typings/user.types';
import get from 'lodash/get';

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: AuthState | null;
}

export interface AuthState {
  company: CompanyNode | null;
  isAdmin: boolean;
  companyList: CompanyNode[];
}

export const initialState = {
  companyList: [],
  isAdmin: false,
  company: null,
};

export const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case Actions.SET_AUTH_INFO:
      const list = get(action, 'payload.companyList', []);
      return {
        ...state,
        company: get(action, 'payload.company', null),
        isAdmin: list.length > 1,
        companyList: list,
      };

    case Actions.CLEAR_AUTH_INFO:
      return {
        ...state,
        company: null,
        isAdmin: false,
        companyList: [],
      };
    default: {
      return state;
    }
  }
};
