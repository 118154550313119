import { CircularProgress } from '@mui/material';

type Size = 'small' | 'medium' | 'large';

const sizeMap: Record<Size, number> = {
  small: 14,
  medium: 20,
  large: 30,
};

export type SpinnerProps = {
  size?: Size;
  className?: string;
  color?: string;
};

export const Spinner = ({ size, color = '#9ca3af', ...props }: SpinnerProps) => {
  return <CircularProgress style={{ color }} thickness={5.2} size={sizeMap[size ?? 'small']} {...(props as any)} />;
};
