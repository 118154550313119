import { BarChartLoader } from '@components/Loader';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetPaidStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload } from 'src/typings/stats.types';

type Props = {
  startDate: StatsPayload['start_date'];
  companyId: StatsPayload['company_id'];
  endDate?: StatsPayload['end_date'];
};

export default function PaidStats({ startDate, endDate, companyId }: Props) {
  const payload = {
    company_id: companyId,
    metric_type: CallStatsMetricType.PAID_STATS,
    start_date: moment(startDate).format('YYYY-MM-DD'),
    end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
  };

  const { data, isLoading } = useGetPaidStats(payload);

  return (
    <div className="shadow-lg rounded-lg p-2 w-1/2 bg-white h-[400px]">
      <div className="text-sm font-medium mb-4">Paid Borrower Details</div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full overflow-hidden">
          <BarChartLoader />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={100} height={100} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="borrowers_dropped"
              stackId="a"
              fill="#8884d8"
              name={`Borrowers dropped since ${moment(startDate).format('DD-MM-YYYY')}`}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
