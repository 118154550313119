import { CallLogDetails } from 'src/typings/call.types';
import get from 'lodash/get';
import { UserGroupIcon, UserIcon } from '@heroicons/react/outline';
import { formatCurrency, getCompanyNameFromCompanyId } from '@utils/call.utils';
import { useContext } from 'react';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
import { convertUTCToIST } from '@utils/time.utils';
import moment from 'moment';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin?: boolean;
};

export default function CallInformation({ callDetails, allowAdmin = false }: Props) {
  const { companyList } = useContext(AuthContext) as AuthContextInterface;

  const crt = get(callDetails, 'customer_requested_time', null);
  const customerRequestedTime = crt ? moment(convertUTCToIST(crt)).format('lll') : 'NA';

  return (
    <div className="grid grid-flow-col grid-cols-12 gap-x-8 p-4 w-full">
      <div className="col-span-6 w-full">
        <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
          <UserIcon className="w-4 h-4 text-primaryButton" />
          Borrower Information
        </div>

        <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Gender</td>
            <td className="border p-2 text-sm font-medium">{get(callDetails, 'borrower.gender', '-')}</td>
          </tr>
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Company</td>
            <td className="border p-2 text-sm font-medium">
              {getCompanyNameFromCompanyId(get(callDetails, 'borrower.company_id', '-'), companyList)}
            </td>
          </tr>
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Due Date</td>
            <td className="border p-2 text-sm font-medium">{get(callDetails, 'borrower.due_date', '-')}</td>
          </tr>
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">Amount Due</td>
            <td className="border p-2 text-sm font-medium">
              {formatCurrency(get(callDetails, 'borrower.amount_due', '-'))}
            </td>
          </tr>
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
              Total Amount Due
            </td>
            <td className="border p-2 text-sm font-medium">
              {formatCurrency(get(callDetails, 'borrower.total_amount', '-'))}
            </td>
          </tr>
          {allowAdmin && (
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                Customer Requested time
              </td>
              <td className="border p-2 text-sm font-medium">{customerRequestedTime}</td>
            </tr>
          )}

          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">Penal Charges</td>
            <td className="border p-2 text-sm font-medium">
              {formatCurrency(get(callDetails, 'borrower.penal_charges', '-'))}
            </td>
          </tr>
        </table>
      </div>
      <div className="col-span-6 w-full">
        <div className="flex justify-start items-center gap-x-2 font-semibold my-4">
          <UserGroupIcon className="w-4 h-4 text-primaryButton" />
          Agent Information
        </div>
        <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Name</td>
            <td className="border p-2 text-sm font-medium">{get(callDetails, 'agent', '-')}</td>
          </tr>
          {allowAdmin && (
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Telephony</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'telephony', '-')}</td>
            </tr>
          )}
          <tr>
            <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48">Call From</td>
            <td className="border p-2 text-sm font-medium">{get(callDetails, 'call_from', '-')}</td>
          </tr>
          {allowAdmin && (
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">Prompt</td>
              <td className="border p-2 text-sm font-medium">{get(callDetails, 'prompt', '-')}</td>
            </tr>
          )}
        </table>
      </div>
    </div>
  );
}
