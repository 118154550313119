import moment from 'moment';
import { useGetCallStats, useGetPickStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload } from 'src/typings/stats.types';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';

type Props = {
  startDate: StatsPayload['start_date'];
  companyId: StatsPayload['company_id'];
  endDate?: StatsPayload['end_date'];
};

export default function CombinedPickStats(props: Props) {
  const payload = {
    company_id: props.companyId,
    metric_type: CallStatsMetricType.PICK_STATS,
    start_date: moment(props.startDate).format('YYYY-MM-DD'),
    end_date: props?.endDate ? moment(props?.endDate).format('YYYY-MM-DD') : undefined,
  };

  const { data: combinedData } = useGetPickStats(payload);
  const { data: combinedCallData } = useGetCallStats({ ...payload, metric_type: CallStatsMetricType.CALL_STATS });

  return (
    <div className="flex flex-col justify-start items-start w-full gap-4">
      <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
        <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
          <div className="text-sm  font-medium mb-4">Pick rate</div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={combinedData?.map((i) => {
                return { ...i, pick_rate: parseFloat((i.pick_rate * 100).toFixed(2)) };
              })}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="pick_rate" stroke="red" /> */}
              <Line type="monotone" dataKey="pick_rate" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
          <div className="text-sm  font-medium mb-4">Unique Pickups</div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={combinedData?.map((i) => {
                return { ...i, pick_rate: parseFloat((i.pick_rate * 100).toFixed(2)) };
              })}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Line type="monotone" dataKey="pick_rate" stroke="red" /> */}
              <Line type="monotone" dataKey="unique_pickups" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="flex justify-between items-start gap-4 w-full">
        <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
          <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
            <div className="text-sm  font-medium mb-4">PTP rate</div>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={combinedData?.map((i) => {
                  return { ...i, ptp_rate: parseFloat((i.ptp_rate * 100).toFixed(2)) };
                })}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Line type="monotone" dataKey="pick_rate" stroke="red" /> */}
                <Line type="monotone" dataKey="ptp_rate" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
            <div className="text-sm  font-medium mb-4">PTP</div>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={combinedData?.map((i) => {
                  return { ...i, pick_rate: (i.pick_rate * 100).toFixed(2) };
                })}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Line type="monotone" dataKey="pick_rate" stroke="red" /> */}
                <Line type="monotone" dataKey="ptp_count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-start gap-4 w-full">
        <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
          <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
            <div className="text-sm  font-medium mb-4">Total Calls</div>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart width={500} height={300} data={combinedCallData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Line type="monotone" dataKey="pick_rate" stroke="red" /> */}
                <Line type="monotone" dataKey="total_calls" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
