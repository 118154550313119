import Cookies from 'js-cookie';

export const ACCESS_TOKEN_KEY = 'at';
export const REFRESH_TOKEN_KEY = 'rt';

export const getAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return Cookies.get(REFRESH_TOKEN_KEY);
};

export const setAccessToken = (accessToken: string) => {
  const inOneHour = 1 / 24;
  Cookies.set(ACCESS_TOKEN_KEY, accessToken, { expires: inOneHour });
};

export const setRefreshToken = (refreshToken: string) => {
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { expires: 7 });
};

export const clearUserCookies = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
};
