import React from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

interface IProps extends ButtonProps {
  propClass?: string;
  isLoading?: boolean;
}

export const PrimaryButton: React.FC<IProps> = ({ ...props }) => {
  const { children, isLoading = false, propClass, type = 'button', ...rest } = props;

  return (
    <button
      type={type}
      {...rest}
      className={twMerge(
        'w-full h-10 inline-flex justify-center items-center rounded-xl font-semibold border border-primaryButton text-white text-base shadow-sm px-4 py-2 bg-primaryButton outline-0',
        'active:bg-primaryButtonActive hover:bg-primaryButtonHover sm:text-sm',
        'disabled:text-white disabled:border-primaryButtonDisabled disabled:bg-primaryButtonDisabled disabled:text-white/40 disabled:cursor-not-allowed',
        'transition-[width] min-w-[100px]',
        propClass,
      )}
    >
      {!isLoading && children}
    </button>
  );
};
