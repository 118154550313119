import { parseLatencyValues } from '@utils/call.utils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TranscriptNode } from 'src/typings/call.types';

type Props = {
  url: string;
  showStats: boolean;
};

export default function Transcript({ url, showStats }: Props) {
  const [transcriptData, setTranscriptData] = useState<TranscriptNode[]>([]);

  const parseTranscriptUrl = async (url: string) => {
    try {
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await res.json();
      const formattedData = data.map((i) => JSON.parse(i)).reverse();
      setTranscriptData(formattedData);
    } catch (error) {
      console.error('Error', error);
    }
  };

  useEffect(() => {
    if (url) {
      parseTranscriptUrl(url);
    }
  }, [url]);

  return (
    <div className="flex flex-col gap-y-3 w-full">
      {transcriptData.map((data) => {
        console.log('data', data);
        return (
          <div className="flex justify-between items-center w-full">
            <div className="text-xs w-36 mr-2">{moment.unix(parseFloat(data.Time)).format('LTS')}</div>
            <div className="flex justify-start items-center gap-x-4 w-full">
              <div className="text-left text-secondary w-14">{data.Speaker}: </div>
              <div className="text-primary font-semibold">{data.Message}</div>
            </div>
            {showStats && (
              <div className="flex justify-end items-center gap-x-2 text-xs w-[400px]">
                {data.LLM! && (
                  <div className="flex justify-start items-center gap-x-2 flex-2 text-nowrap">
                    Name: <div className="font-semibold ">{data.LLM}</div>
                  </div>
                )}
                {data.Confidence && (
                  <div className="flex justify-start items-center gap-x-2 flex-2 text-nowrap">
                    Confidence: <div className="font-semibold ">{data.Confidence.toFixed(3)}</div>
                  </div>
                )}
                {data.Latency.LLM && (
                  <div className="flex justify-start items-center gap-x-2">
                    LLM: <div className="font-semibold">{parseLatencyValues(data.Latency.LLM)}s</div>
                  </div>
                )}
                {data.Latency.STT && (
                  <div className="flex justify-start items-center gap-x-2">
                    STT: <div className="font-semibold">{parseLatencyValues(data.Latency.STT)}s</div>
                  </div>
                )}
                {data.Latency.TTS && (
                  <div className="flex justify-start items-center gap-x-2">
                    TTS: <div className="font-semibold">{parseLatencyValues(data.Latency.TTS)}s</div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
