/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ChevronDownIcon } from '@heroicons/react/solid';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IconButton } from '@components/Buttons';
import { useOnClickOutside } from 'src/hooks/utils';
import { InputLabel } from '@components/TextField';

export interface DropdownItem {
  id: string | number;
  name: string;
  class?: string;
  [property: string]: any;
}

interface DropPosition {
  left: number;
  top: number;
}

interface IProps {
  options: DropdownItem[];
  disabled?: boolean;
  designVisible?: boolean;
  inputClass?: string;
  label?: string;
  defaultValue?: string;
  isEditable?: boolean;
  inputBoxClass?: string;
  optionsClass?: string;
  onSelect?: (selectedItem: DropdownItem) => void;
  placeholder?: string;
  onChange?: (value: string) => void;
  overflowActive?: boolean;
  mandatory?: boolean;
}

export default function Dropdown(props: IProps) {
  const {
    options,
    disabled,
    defaultValue,
    inputBoxClass,
    onSelect,
    placeholder,
    onChange,
    designVisible,
    inputClass,
    optionsClass,
    label,
    mandatory,
    isEditable,
    overflowActive,
  } = props;
  const [showOptions, toggleShowOptions] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [value, setValue] = useState<string>(defaultValue || '');
  const [selectedValue, setSelectedValue] = useState<DropdownItem | null>(null);
  const dropDownOptionsRef = useRef<HTMLDivElement>(null);
  const [dropPosition, setDropPosition] = useState<DropPosition>({
    left: 0,
    top: 0,
  });
  const dropDown = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropDownOptionsRef, () => toggleShowOptions(false));

  const handleOnChange = (val: string) => {
    setValue(val);
    if (!val && onChange) {
      onChange(val);
    }
    if (options) {
      const filteredItems = options.filter((item) => {
        const searchQuery = val.toLowerCase();
        const itemName = item?.name?.toLowerCase();
        return itemName ? itemName.includes(searchQuery) : false;
      });
      setFilteredOptions(filteredItems);
    }
  };

  const selectOption = (item: DropdownItem) => {
    setSelectedValue(item);
    if (item?.name) {
      setValue(item.name);
    }
    if (onSelect) {
      onSelect(item);
      toggleShowOptions(false);
    }
  };

  const toggleDropdown = () => {
    !disabled && toggleShowOptions(!showOptions);
  };

  const updateDropDownPosition = () => {
    if (overflowActive) {
      const { top, left, height } = dropDown?.current?.getBoundingClientRect() || {};
      setDropPosition({
        top: top + height,
        left: left,
      });
    }
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    updateDropDownPosition();
    document.addEventListener(
      'scroll',
      () => {
        updateDropDownPosition();
      },
      true,
    );

    return () => {
      document.removeEventListener(
        'scroll',
        () => {
          updateDropDownPosition();
        },
        true,
      );
    };
  }, []);

  return (
    <div className="relative w-full" role="dropdown">
      {label && <InputLabel label={label} mandatory={mandatory} />}
      <div ref={dropDown}>
        {designVisible ? (
          <div
            onClick={toggleDropdown}
            className="cursor-pointer rounded-md font-semibold text-textPrimary text-center w-28 bg-primaryButton"
          >
            {defaultValue}
          </div>
        ) : (
          <div
            className={clsx(
              'bg-inputBgColor text-textPrimary border border-gray-300 flex justify-between items-center rounded-xl h-10',
              disabled ? ' cursor-not-allowed' : undefined,
              inputBoxClass?.length
                ? inputBoxClass
                : 'border border-borderColor flex justify-between items-center rounded-xl p-2',
            )}
          >
            <input
              className={clsx(
                disabled ? 'bg-inputBgColor cursor-not-allowed' : undefined,
                'outline-0 text-sm w-full bg-inputBgColor border-0 h-9 focus:border-0 focus:outline-0 focus:ring-0 focus-within:border-0 focus-within:outline-0',
                inputClass,
              )}
              role="text-input"
              onChange={(event) => handleOnChange(event.target.value)}
              onFocus={() => toggleShowOptions(true)}
              onBlur={() => setTimeout(() => toggleShowOptions(false), 100)}
              value={value}
              disabled={disabled || !!isEditable}
              placeholder={placeholder}
            />
            <IconButton onClick={toggleDropdown} size={'md'} propClass="hover:bg-gray-700">
              <ChevronDownIcon
                className={clsx(
                  showOptions ? 'rotate-180' : undefined,
                  'w-5 h-5 text-iconDark cursor-pointer transition-transform',
                )}
              />
            </IconButton>
          </div>
        )}
      </div>
      <AnimatePresence>
        {!disabled && showOptions && (
          <motion.div
            initial={{ y: -50, opacity: 0.5 }}
            exit={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.2, type: 'spring' }}
            ref={dropDownOptionsRef}
            className={clsx(
              'h-auto max-h-40 overflow-y-scroll z-50 rounded-lg shadow-[0_0_32px_rgba(0,0,0,0.06)] border border-gray-300 bg-popupBgColor',
              optionsClass ? optionsClass : 'bg-popupBgColor w-full',
              overflowActive ? 'fixed' : 'absolute',
            )}
            style={
              overflowActive
                ? {
                    top: dropPosition.top,
                    left: dropPosition.left,
                  }
                : {}
            }
          >
            <div className="flex flex-col gap-y-2 p-2">
              {filteredOptions &&
                filteredOptions.map((option) => {
                  const isSelected = selectedValue?.id === option.id;
                  return (
                    <div
                      key={option.id}
                      onClick={() => selectOption(option)}
                      role="option"
                      className={clsx(
                        'text-textPrimary text-sm p-2 rounded-lg hover:bg-gray-200 cursor-pointer',
                        option?.class,
                        isSelected ? 'bg-blue-100' : '',
                      )}
                    >
                      {option.name}
                    </div>
                  );
                })}
              {value && filteredOptions.length === 0 && (
                <div className="text-sm p-2 rounded-lg text-textTertiary">No options found</div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
