import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { CenterModalWithHeader } from '@components/Modal/CenterModalWithHeader';
import { InputLabel, TextField } from '@components/TextField';
import { useState } from 'react';

type Props = {
  showModal: boolean;
  onClose: () => void;
  sourceFolder?: string;
  targetFolder?: string;
  onSave: ({ sourceFolder, targetFolder }: { sourceFolder: string; targetFolder: string }) => void;
};

export default function DriveFolderManager({ targetFolder: tf, sourceFolder: sf, showModal, onClose, onSave }: Props) {
  const [sourceFolder, setSourceFolder] = useState<string>(sf || '');
  const [targetFolder, setTargetFolder] = useState<string>(tf || '');

  const onSaveFolders = () => {
    onSave({
      sourceFolder,
      targetFolder,
    });
    onClose();
  };

  return (
    <CenterModalWithHeader
      title="Select Drive Folders"
      shouldClose
      isVisible={showModal}
      className="w-[400px]"
      onClose={onClose}
    >
      <div className="p-6 max-h-[650px] h-full overflow-y-scroll">
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-1 w-full">
            <InputLabel label="Source Folder Id" />
            <TextField
              placeholder="Add source folder id"
              value={sourceFolder}
              onChange={(e) => setSourceFolder(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1">
            <InputLabel label="Target Folder Id" />
            <TextField
              placeholder="Add target folder id"
              value={targetFolder}
              onChange={(e) => setTargetFolder(e.target.value)}
            />
          </div>
          <div className="flex justify-end items-center mt-2 gap-x-4">
            <SecondaryButton propClass="w-20" onClick={onClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onSaveFolders} disabled={!sourceFolder || !targetFolder} propClass="w-10">
              Continue
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CenterModalWithHeader>
  );
}
