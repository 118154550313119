import { useEffect, useReducer } from 'react';
import * as Actions from '../../reducers/actions';
import { AuthContext } from './AuthContext';
import { authReducer, AuthState, initialState } from '../../reducers/authReducer';
import { useGetCompanyList } from 'src/hooks/apis/company';

interface IProps {
  children: JSX.Element;
}

export default function AuthProvider(props: IProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, initialState);
  const apiKey = localStorage.getItem('en');

  const { data, isLoading } = useGetCompanyList({ enabled: !!apiKey });

  useEffect(() => {
    if (data) {
      let company = null;
      if (data?.length === 1) {
        company = data[0];
      }
      setAuthInfo({
        company: company,
        isAdmin: data?.length > 1,
        companyList: data || [],
      });
    }
  }, [data, apiKey]);

  const clearAuthInfo = () => {
    dispatch({ type: Actions.CLEAR_AUTH_INFO, payload: null });
  };

  const setAuthInfo = (authInfo: AuthState | null) => {
    dispatch({ type: Actions.SET_AUTH_INFO, payload: authInfo });
  };

  if (isLoading || data?.length == 0) {
    return <div className="flex justify-start items-center w-full h-full p-10">Fetching....</div>;
  }

  return <AuthContext.Provider value={{ ...state, setAuthInfo, clearAuthInfo }}>{children}</AuthContext.Provider>;
}
