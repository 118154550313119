import { BarChartLoader } from '@components/Loader';
import { prepareTimeOfDayData } from '@utils/stats.utils';
import moment from 'moment';
import { useMemo } from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts';
import { useGetPickStats } from 'src/hooks/apis/stats';
import { CallStatsMetricType, StatsPayload, TimeOfDay } from 'src/typings/stats.types';

type Props = {
  startDate: StatsPayload['start_date'];
  companyId: StatsPayload['company_id'];
  endDate?: StatsPayload['end_date'];
};

export default function TimeOfDayStats(props: Props) {
  const payload = {
    company_id: props.companyId,
    metric_type: CallStatsMetricType.PICK_STATS,
    start_date: moment(props.startDate).format('YYYY-MM-DD'),
    end_date: props?.endDate ? moment(props?.endDate).format('YYYY-MM-DD') : undefined,
  };
  const { data: morningData, isLoading: isLoading1 } = useGetPickStats({
    ...payload,
    time_of_day: TimeOfDay.MORNING,
  });
  const { data: afternoonData, isLoading: isLoading2 } = useGetPickStats({
    ...payload,
    time_of_day: TimeOfDay.AFTERNOON,
  });
  const { data: eveningData, isLoading: isLoading3 } = useGetPickStats({ ...payload, time_of_day: TimeOfDay.EVENING });

  const combinedTimeOfDayData = useMemo(() => {
    if (morningData && afternoonData && eveningData) {
      return prepareTimeOfDayData(morningData, afternoonData, eveningData);
    } else {
      return null;
    }
  }, [morningData, afternoonData, eveningData]);

  const isDataLoading = isLoading1 || isLoading2 || isLoading3;
  const data = combinedTimeOfDayData && morningData && afternoonData && eveningData;

  return (
    <div className="flex justify-between items-start gap-4 w-full">
      {isDataLoading && (
        <div className="flex justify-between items-center overflow-hidden shadow-lg rounded-lg gap-x-4 p-2 w-full  h-[300px] bg-white">
          <BarChartLoader />
          <BarChartLoader />
        </div>
      )}
      {!isDataLoading && data && (
        <>
          <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
            <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
              <div className="text-sm  font-medium mb-4">Time of day Pick rate</div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={500} data={combinedTimeOfDayData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="pickRate1" stroke="blue" activeDot={{ r: 8 }} name="Morning" />
                  <Line type="monotone" dataKey="pickRate2" stroke="red" activeDot={{ r: 8 }} name="Afternoon" />
                  <Line type="monotone" dataKey="pickRate3" stroke="green" activeDot={{ r: 8 }} name="Evening" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="flex justify-between items-start gap-4 w-full h-[300px] my-4">
            <div key={`line-overall`} className="shadow-lg rounded-lg p-2 w-full h-full bg-white">
              <div className="text-sm  font-medium mb-4">Time of day PTP rate</div>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={500} data={combinedTimeOfDayData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="ptp1" stroke="blue" activeDot={{ r: 8 }} name="Morning" />
                  <Line type="monotone" dataKey="ptp2" stroke="red" activeDot={{ r: 8 }} name="Afternoon" />
                  <Line type="monotone" dataKey="ptp3" stroke="green" activeDot={{ r: 8 }} name="Evening" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
