import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Merge } from '../../typings/utils';
import { InputLabel } from './InputLabel';
import { ErrorDisplay } from '@components/ErrorDisplay';

type Size = 'small' | 'medium' | 'large';

const SizeVariant: Record<Size, string> = {
  small: 'px-[0.62rem] py-[0.26rem] text-[0.74rem]',
  medium: 'px-[0.82rem] py-[0.34rem] text-[0.84rem]',
  large: 'px-[1.2rem] py-[0.4rem] text-[0.98rem]',
};

type InputBaseProps = {
  error?: string;
  containerProps?: string;
  inputContainerProps?: string;
  label?: string;
  LeftComponent?: React.ReactNode;
  size?: Size;
  RightOutsideComponent?: React.ReactNode;
  mandatory?: boolean;
  borderless?: boolean;
};

type TextFieldProps = Merge<React.InputHTMLAttributes<HTMLInputElement>, InputBaseProps>;

export const TextAreaField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      size = 'medium',
      mandatory = false,
      label,
      error,
      LeftComponent,
      containerProps,
      RightOutsideComponent,
      inputContainerProps,
      textAreaProps,
      borderless = false,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={`flex w-full bg-transparent ${containerProps}`}>
        <div className="flex flex-col w-full p-0">
          {label ? <InputLabel label={label} mandatory={mandatory} /> : null}
          <div
            className={twMerge(
              'flex w-full bg-inputBgColor relative items-center rounded-xl overflow-hidden',
              inputContainerProps,
            )}
          >
            {LeftComponent ? <div className="ml-2">{LeftComponent}</div> : null}
            <textarea
              className={twMerge(
                `caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-borderColor font-medium rounded-xl w-full h-28 disabled:bg-textDisabled disabled:cursor-not-allowed placeholder-shown:truncate placeholder-shown:text-inputPlaceholderColor focus:border-primaryButton ${SizeVariant[size]} `,
                textAreaProps,
                borderless && 'bg-transparent disabled:bg-transparent',
                error ? 'border-pastelRed focus:border-pastelRed' : '',
                borderless && !error && 'border-none focus:outline-none',
              )}
              {...props}
              ref={ref}
            />
          </div>
          <ErrorDisplay error={error} />
        </div>
        {RightOutsideComponent}
      </div>
    );
  },
);
