import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from '@components/ProtectedRoute';
import { FullPageLoader } from '@components/Loader';
import Dashboard from '../pages/Dashboard';
import CallDetailsPage from '../pages/CallDetailsPage';
import Login from '../pages/Login';
import CallStatsPage from 'src/pages/CallStatsPage';
import CollectionAgentForm from 'src/pages/CollectionAgentForm';
import ScheduledCallDetailsPage from 'src/pages/ScheduledCallDetailsPage';
import SpamManagerPage from 'src/pages/SpamManager';
import OpsManagementPage from 'src/pages/OpsManagementPage';
import GoogleLogin from 'src/pages/GoogleLogin';
import { AuthProvider } from 'src/contexts/auth';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="dashboard">
        <Route
          index
          element={
            <AuthProvider>
              <ProtectedRoute isPrivate={true}>
                <Suspense fallback={<FullPageLoader />}>
                  <Dashboard />
                </Suspense>
              </ProtectedRoute>
            </AuthProvider>
          }
        />
        <Route
          path=":id"
          element={
            <AuthProvider>
              <ProtectedRoute isPrivate={true}>
                <Suspense fallback={<FullPageLoader />}>
                  <CallDetailsPage />
                </Suspense>
              </ProtectedRoute>
            </AuthProvider>
          }
        />
      </Route>

      <Route
        path="scheduled/:id"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <ScheduledCallDetailsPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/stats"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <CallStatsPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />

      <Route
        path="/"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <CollectionAgentForm />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/spam"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              <Suspense fallback={<FullPageLoader />}>
                <SpamManagerPage />
              </Suspense>
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route
        path="/ops"
        element={
          <AuthProvider>
            <ProtectedRoute isPrivate={true}>
              {/* <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}> */}
              <Suspense fallback={<FullPageLoader />}>
                <OpsManagementPage />
              </Suspense>
              {/* </GoogleOAuthProvider> */}
            </ProtectedRoute>
          </AuthProvider>
        }
      />
      <Route path="/google-login" element={<GoogleLogin />} />
      <Route
        path="login"
        element={
          <ProtectedRoute isPrivate={false}>
            <Suspense fallback={<FullPageLoader />}>
              <Login />
            </Suspense>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
