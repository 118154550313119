import { FC } from 'react';

interface ErrorProps {
  error: string | string[];
}

const ErrorDisplay: FC<ErrorProps> = ({ error }) => {
  const isErrorMultiline = Array.isArray(error);

  if (error?.length === 0) {
    return null;
  }

  return (
    <div className="error-container">
      {isErrorMultiline ? (
        <ul className="pl-3">
          {error.map((errorMsg, index) => (
            <li key={index} className="text-pastelRed text-xs">
              {errorMsg}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-pastelRed text-xs">{error}</p>
      )}
    </div>
  );
};

export default ErrorDisplay;
