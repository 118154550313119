import { SecondaryButton } from '@components/Buttons';
import GoogleIcon from '../assets/images/google-icon.png';
import { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

type Props = {};

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const GOOGLE_SECRET_KEY = import.meta.env.VITE_GOOGLE_SECRET_KEY;

export default function GoogleLogin({}: Props) {
  const accessToken = localStorage.getItem('at');
  const refreshToken = localStorage.getItem('rt');

  const [searchParams] = useSearchParams();
  const referrer: string | null = searchParams.get('referrer');

  const navigateTo = useNavigate();
  const CLIENT_ID = GOOGLE_CLIENT_ID; // Replace with your actual client ID
  const CLIENT_SECRET = GOOGLE_SECRET_KEY; // Replace with your actual client secret
  const REDIRECT_URI = `${window.location.origin}/google-login`; // Your redirect URI
  const AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
  const TOKEN_ENDPOINT = 'https://oauth2.googleapis.com/token';

  const SCOPES = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/drive',
  ].join(' ');

  const handleLogin = () => {
    const authUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_URI,
    )}&response_type=code&scope=${encodeURIComponent(SCOPES)}&access_type=offline&prompt=consent`;

    window.location.href = authUrl;
  };

  const getTokens = async (code: string) => {
    const response = await fetch(TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        code,
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        redirect_uri: REDIRECT_URI,
        grant_type: 'authorization_code',
      }),
    });

    const data = await response.json();
    if (data.access_token && data.refresh_token) {
      localStorage.setItem('at', data.access_token);
      localStorage.setItem('rt', data.refresh_token);

      referrer ? navigateTo(referrer) : navigateTo('/ops');

      // Use access token for API requests
    } else {
      console.error('Failed to get tokens:', data);
    }
  };

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('rt');
    if (!refreshToken) return;

    const response = await fetch(TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      }),
    });

    const data = await response.json();
    if (data.access_token) {
      localStorage.setItem('at', data.access_token);
      referrer ? navigateTo(referrer) : navigateTo('/ops');
    } else {
      console.error('Failed to refresh access token:', data);
    }
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');

    if (code) {
      getTokens(code);
    }

    if (!accessToken) {
      refreshAccessToken();
    }
  }, []);

  if (!accessToken && !refreshToken) {
    return (
      <div className="flex flex-col items-center justify-start w-full h-full">
        <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-8 py-4 md:py-10 max-h-screen bg-[#F8F8FA]">
          <div className="flex flex-col items-center justify-center w-full h-screen bg-white rounded-xl">
            <div className="text-sm text-center mt-3 mb-8 text-textSecondary">
              We suggest using <strong>the email account you use for work</strong>
            </div>
            <div className="text-center mb-4">
              <SecondaryButton onClick={handleLogin}>
                <img src={GoogleIcon} className="w-5 h-5 mr-[0.5em] bg-transparent" alt="google-icon" />
                <span>Sign in with Google</span>
              </SecondaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    <Navigate to="/ops" />;
  }
}
