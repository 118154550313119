import { useMutation, useQueryClient } from 'react-query';
import { CoreAPIs } from '../../../api';
import { CallLog, CallLogDetails } from 'src/typings/call.types';
import { callLogs } from 'src/queryClient/queryKeys';

type Params = {
  callId: CallLog['id'];
  payload: Partial<CallLogDetails>;
};

const updateCallLogDetails = async (params: Params): Promise<CallLogDetails> => {
  const data = await CoreAPIs.updateCallLogDetails(params.callId, params.payload);
  return data;
};

export const useUpdateCallLogDetails = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<CallLogDetails, Error, Params, unknown>(updateCallLogDetails, {
    onSuccess: (responseData, params) => {
      const { callId } = params;
      queryClient.invalidateQueries([callLogs, callId]);
    },
  });
  return mutation;
};
