type Props = {
  children: JSX.Element | string;
  title?: string;
};

export default function Card({ children, title }: Props) {
  return (
    <div className="py-3 px-4 rounded-xl bg-white flex flex-col justify-start items-start gap-2 w-full">
      {title && <div className="text-lg font-bold">{title}</div>}
      <div className="w-full">{children}</div>
    </div>
  );
}
