import { getCallSummary } from '@utils/call.utils';
import { CallLogDetails } from 'src/typings/call.types';
import get from 'lodash/get';

type Props = {
  callDetails: CallLogDetails;
  allowAdmin?: boolean;
};

export default function PreviousCallContext({ callDetails }: Props) {
  const prevCallContext = callDetails?.call_summary ? getCallSummary(callDetails?.call_summary) : null;

  if (prevCallContext === null)
    return <div className="flex justify-between items-start gap-x-8">No Previous call context found</div>;

  return (
    <div className="flex justify-between items-start gap-x-8">
      <div className="flex flex-col gap-y-4 w-1/2 my-4">
        <div className="flex flex-col w-full">
          <div className="flex justify-start items-center gap-x-2 font-semibold">Borrower Previous call context</div>
          <table className="border p-2 w-full mt-4 outline outline-1 outline-slate-200 rounded-lg overflow-hidden">
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">Delay Reason</td>
              <td className="border p-2 text-sm font-medium">
                {get(prevCallContext, 'delay_reason', null) || get(prevCallContext, 'summary', '-')}
              </td>
            </tr>
            <tr>
              <td className="border p-2 bg-gray-100 text-blue-950 text-sm font-medium w-48 align-top">
                Borrower Attitude
              </td>
              <td className="border p-2 text-sm font-medium">{get(prevCallContext, 'borrower_attitude', '-')}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
