import { Card } from '@components/Card';
import { CallDetailsTab, CallLogDetails } from 'src/typings/call.types';
import { twMerge } from 'tailwind-merge';
import { useContext, useRef, useState } from 'react';
import LatencyDetails from './sections/LatencyDetails';
import CallInformation from './sections/CallInformation';
import CallRecording from './sections/CallRecording';
import CallAnalysis from './sections/CallAnalysis';
import BorrowerHistory from './sections/BorrowerHistory';
import EmotionAnalysis from './sections/EmotionAnalysis';
import CallSummary from './sections/CallSummary';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';
import PreviousCallContext from './sections/PreviousCallContext';
import { filterAllowedTabs } from '@utils/call.utils';

type Props = {
  callDetails: CallLogDetails;
};

export default function CallLogDetailsPage({ callDetails }: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const tabNodeRefArr = useRef<HTMLDivElement[]>([]);
  const { isAdmin } = useContext(AuthContext) as AuthContextInterface;

  if (!callDetails) return null;

  const left =
    tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().x -
      tabNodeRefArr.current[0]?.getBoundingClientRect().x || 0;

  const width = tabNodeRefArr.current[selectedIndex]?.getBoundingClientRect().width;

  const tabs: CallDetailsTab[] = [
    { id: 0, name: 'Information', isAllowed: true },
    { id: 1, name: 'Call recording', isAllowed: true },
    { id: 2, name: 'Call Analysis', isAllowed: true },
    { id: 3, name: 'Latency Details', isAllowed: isAdmin },
    { id: 4, name: 'Borrower History', isAllowed: true },
    { id: 5, name: 'Emotion Analysis', isAllowed: false && isAdmin },
    { id: 6, name: 'Previous Call Context', isAllowed: isAdmin },
  ];
  const allowedTabs = filterAllowedTabs(tabs);

  return (
    <div className="flex flex-col gap-y-4">
      <Card>
        <CallSummary callDetails={callDetails} allowAdmin={isAdmin} />
      </Card>
      <Card>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex justify-start items-center gap-x-2 relative w-full">
            {allowedTabs?.map((tab, index: number) => {
              const isSelected = selectedIndex === tab.id; //selectedResponseCode?.responseCode === res.responseCode;
              return (
                <div
                  key={`apiResponse-${index}`}
                  className={twMerge(`cursor-pointer p-2 font-medium`, isSelected ? 'py-2' : '')}
                  ref={(element: HTMLDivElement) => (tabNodeRefArr.current[tab.id] = element)}
                  onClick={() => {
                    setSelectedIndex(tab.id);
                  }}
                >
                  {tab.name}
                </div>
              );
            })}
            <div
              className="h-1 bg-[#6f9feb] absolute -bottom-1 left-0 rounded-lg transition-[left] ease-in-out duration-200"
              style={{ left, width: width || '100px' }}
            ></div>
          </div>

          <div className="w-full">
            {selectedIndex === 0 && <CallInformation callDetails={callDetails} allowAdmin={isAdmin} />}
            {selectedIndex === 1 && <CallRecording callDetails={callDetails} allowAdmin={isAdmin} />}
            {selectedIndex === 2 && <CallAnalysis callDetails={callDetails} allowAdmin={isAdmin} />}
            {selectedIndex === 3 && <LatencyDetails callDetails={callDetails} />}
            {selectedIndex === 4 && <BorrowerHistory borrowerId={callDetails?.borrower.id} />}
            {selectedIndex === 5 && <EmotionAnalysis callDetails={callDetails} />}
            {selectedIndex === 6 && <PreviousCallContext callDetails={callDetails} />}
          </div>
        </div>
      </Card>
    </div>
  );
}
