import { TextField } from '@components/TextField';
import { PrimaryButton } from '@components/Buttons';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

type FormData = {
  key: string;
};

export default function LoginContainer() {
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();

  const referrer: string = searchParams.get('referrer') || '';

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      key: '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = async () => {
    localStorage.setItem('en', getValues().key);

    // await axios.post(url, payload);
    toast.success('Authenticated successfully.');
    if (referrer) {
      // Navigate to referrer currently present in url
      navigateTo(referrer, { replace: true });
    } else {
      navigateTo('/dashboard', { replace: true });
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-y-4 md:gap-y-6">
      <div className="flex flex-col items-start justify-start w-full lg:w-1/3 gap-2 p-5">
        <div className="text-3xl text-primary font-semibold">Welcome to Knowl!</div>
      </div>

      <form className="w-full lg:w-1/3 flex flex-col gap-y-5 p-5" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Key"
          placeholder="Please enter a Valid Key"
          type="string"
          mandatory
          {...register('key', {
            required: 'This field is mandatory',
            setValueAs: (value: string) => value.trim(),
          })}
        />
        <div className="w-1/2">
          <PrimaryButton propClass="text-sm lg:text-md w-24 lg:w-32" type="submit" disabled={!isValid || isSubmitting}>
            Submit
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
