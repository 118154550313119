import { LiteralUnion } from '../typings/utils';

export type GlobalEventNames = 'forceLogout';

export type EventEmitterEventName = LiteralUnion<GlobalEventNames, string>;

interface Events {
  [key: string]: Function[];
}

export class EventEmitterInitializer {
  public events: Events;

  constructor(events?: Events) {
    this.events = events || {};
  }

  public subscribe(name: EventEmitterEventName, cb: Function) {
    (this.events[name] || (this.events[name] = [])).push(cb);

    return {
      unsubscribe: () =>
        this.events[name] &&
        // eslint-disable-next-line no-bitwise
        this.events[name].splice(this.events[name].indexOf(cb) >>> 0, 1),
    };
  }

  public emit(name: EventEmitterEventName, ...args: any[]): void {
    (this.events[name] || []).forEach((fn) => fn(...args));
  }

  public isEventActive(name: EventEmitterEventName) {
    return Boolean(this.events[name] && this.events[name].length > 0);
  }
}

export const EventEmitter = new EventEmitterInitializer();
