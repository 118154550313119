import { useEffect, useState } from 'react';
import { CogIcon, DocumentTextIcon, MicrophoneIcon } from '@heroicons/react/outline';
import { FileNode, FileMimeType } from 'src/typings/googleDrive.types';
import { GoogleAPIs } from 'src/api';
import { FileLoader } from './components/loaders';
import { DriveFolderManager } from './components/driveFolderManager';
import FileDetailsManager from './FileDetailsManager';
import { BanIcon, FolderIcon, KeyIcon } from '@heroicons/react/solid';
import { twMerge } from 'tailwind-merge';
import { IconButton } from '@mui/material';
import { Popover, PopoverPosition } from '@components/Popover';
import { DeepgramKeyManager } from './components/deepgramKeyManager';

type Props = {};

type DriveFolders = {
  sourceFolder: string;
  targetFolder: string;
};

export default function OpsManager({}: Props) {
  const prevData = localStorage.getItem('df');
  const dgKey = localStorage.getItem('dg');
  const [showModel, toggleShowModal] = useState<boolean>(false);
  const [showDGModel, toggleShowDGModal] = useState<boolean>(false);
  const [showSettingsPopup, toggleSettingsPopup] = useState<boolean>(false);
  const [driveFolders, setDriveFolders] = useState<DriveFolders | null>(prevData ? JSON.parse(prevData) : null);
  const [files, setFiles] = useState<FileNode[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileNode | null>(null);
  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);

  const onUpdateFolders = (data: DriveFolders) => {
    setDriveFolders(data);
    const { sourceFolder, targetFolder } = data;
    localStorage.setItem('df', JSON.stringify({ sourceFolder, targetFolder }));
  };

  const fetchFolders = async (folderId: string) => {
    try {
      if (!folderId) return;
      setIsFilesLoading(true);
      const data = await GoogleAPIs.fetchDriveFolder(folderId);
      const filteredAudioFiles = data.files.filter((i: FileNode) => i.mimeType.includes('audio/'));
      setFiles(filteredAudioFiles);
    } catch (error) {
      console.error('Error in fetching folder data :', error);
    } finally {
      setIsFilesLoading(false);
    }
  };

  const onSelectFile = (file: FileNode) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (dgKey === null) {
      toggleShowDGModal(true);
    } else if (!driveFolders) {
      toggleShowModal(true);
    } else if (driveFolders && driveFolders.sourceFolder) {
      fetchFolders(driveFolders.sourceFolder);
    }
  }, [driveFolders, dgKey]);

  return (
    <>
      <div className="grid grid-flow-col grid-cols-12 gap-x-2 h-screen bg-white p-2 rounded-lg">
        <div className="col-span-4 flex flex-col gap-4 h-screen border-r pr-3">
          <div className="flex justify-between items-center">
            <div className="font-medium text-lg">Files ({files?.length})</div>
            <div className="relative">
              <IconButton onClick={() => toggleSettingsPopup(true)} title="Settings">
                <CogIcon className="w-5 h-5" />
              </IconButton>
              <Popover
                contentPropClass="w-[280px] mr-2 overflow-hidden"
                showPopover={showSettingsPopup}
                contentPosition={PopoverPosition.BottomRight}
                onClose={() => toggleSettingsPopup(false)}
              >
                <div className="flex flex-col items-start justify-start gap-2">
                  <div
                    onClick={() => toggleShowModal(true)}
                    className="flex justify-start items-center gap-x-2 p-2 cursor-pointer hover:bg-slate-100 w-full rounded-lg"
                  >
                    <FolderIcon className="w-5 h-5 text-primaryButton" />
                    <div className="font-medium text-sm text-primary">Change Folders</div>
                  </div>
                  <div
                    onClick={() => toggleShowDGModal(true)}
                    className="flex justify-start items-center gap-x-2 p-2 cursor-pointer hover:bg-slate-100 w-full rounded-lg"
                  >
                    <KeyIcon className="w-5 h-5 text-primaryButton" />
                    <div className="font-medium text-sm text-primary">Manage Deepgram Key</div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          {isFilesLoading ? (
            <FileLoader />
          ) : (
            <div className="flex flex-col gap-y-2 pr-2 pb-40 h-full overflow-scroll">
              {files && files.length > 0 ? (
                files.map((file) => {
                  const isSelected = selectedFile?.id === file.id;
                  return (
                    <div
                      onClick={() => onSelectFile(file)}
                      key={file.id}
                      className={twMerge(
                        'flex justify-start items-center gap-x-2 cursor-pointer border rounded-xl p-2 hover:bg-blue-50 ',
                        isSelected ? 'bg-blue-100' : '',
                      )}
                    >
                      {file.mimeType === FileMimeType.Audio ? (
                        <MicrophoneIcon className="w-5 h-5 min-w-[20px] min-h-[20px]" />
                      ) : (
                        <DocumentTextIcon className="w-5 h-5 min-w-[20px] min-h-[20px]" />
                      )}
                      <div className="text-sm font-medium truncate">{file.name}</div>
                    </div>
                  );
                })
              ) : (
                <div className="flex justify-center items-center gap-2">
                  <BanIcon className="w-7 h-7 text-yellow-500 " />
                  <div className="text-yellow-800 flex justify-start items-center gap-x-1">
                    No Audio files found in{' '}
                    <div
                      onClick={() => toggleShowModal(true)}
                      className="flex justify-start items-center gap-x-2 text-blue-500 underline cursor-pointer hover:bg-blue-50 hover:rounded-lg p-1"
                    >
                      source folder
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {selectedFile && driveFolders ? (
          <FileDetailsManager
            selectedFile={selectedFile}
            sourceFolder={driveFolders.sourceFolder}
            targetFolder={driveFolders.targetFolder}
          />
        ) : (
          <div className="col-span-8 flex justify-center items-start w-full h-full pt-10 font-medium">
            Select file to continue
          </div>
        )}
      </div>
      <DriveFolderManager
        sourceFolder={driveFolders?.sourceFolder}
        targetFolder={driveFolders?.targetFolder}
        showModal={showModel}
        onClose={() => toggleShowModal(false)}
        onSave={onUpdateFolders}
      />
      <DeepgramKeyManager showModal={showDGModel} onClose={() => toggleShowDGModal(false)} />
    </>
  );
}
