import { TopNavbar } from '@components/TopNavbar';
import { SpamManager } from 'src/container/SpamManager';

type Props = {};

export default function SpamManagerPage({}: Props) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <TopNavbar showBackButton={false} pageTitle="Phone number Manager" />
      <div className="flex justify-center w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]">
        <SpamManager />
      </div>
    </div>
  );
}
