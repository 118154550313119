import { SkeletonLoader } from '@components/SkeletonLoader';

type Props = {};

export default function TranscriptLoader({}: Props) {
  return (
    <div className="flex flex-col gap-4 w-full">
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={`t-loader-${index}`} className="flex flex-col gap-4">
          <div className="flex justify-start items-center gap-x-4">
            <SkeletonLoader width={100} height={25} />
            <SkeletonLoader width={'100%'} height={25} />
          </div>
          <div className="flex justify-start items-center gap-x-4">
            <SkeletonLoader width={80} height={25} />
            <SkeletonLoader width={'100%'} height={25} />
          </div>
        </div>
      ))}
    </div>
  );
}
