import { XIcon } from '@heroicons/react/outline';
import React from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const CloseButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className="w-6 h-6 bg-gray-200 flex justify-center items-center rounded-full cursor-pointer hover:bg-gray-300"
    >
      <XIcon className="w-4 h-4" />
    </button>
  );
};
