import { AudioBar } from '@components/AudioBar';
import { PlayIcon } from '@heroicons/react/solid';
import { IconButton, Skeleton } from '@mui/material';
import { useState } from 'react';
import { useGetCallLogDetails } from 'src/hooks/apis/calls';
import { CallLog } from 'src/typings/call.types';

type Props = {
  callId: CallLog['call_sid'];
};

export default function InlineAudioBar({ callId }: Props) {
  const [enable, toggleEnabled] = useState<boolean>(false);

  const { data, isLoading } = useGetCallLogDetails({ callId, enabled: enable });

  const onPress = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleEnabled(true);
  };

  return (
    <div className="flex justify-start items-center h-8 w-full">
      {!enable && !data && (
        <IconButton title="Play call recording" onClick={(e) => onPress(e)}>
          <PlayIcon className="w-4 h-4 text-primaryButton" />
        </IconButton>
      )}
      {isLoading ? (
        <Skeleton width={'100%'} height={40} className="px-4" />
      ) : data ? (
        <AudioBar audioURL={data.recording} audioClass="h-8 w-40 flex justify-center items-center text-xs m-0" />
      ) : null}
    </div>
  );
}
