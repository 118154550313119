import { Dropdown } from '@components/Dropdown';
import { InputLabel, TextField } from '@components/TextField';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { IconButton } from '@mui/material';
import { getParticipantsArray } from '@utils/deepgram.utils';
import { useState } from 'react';
import { Participants } from 'src/typings/deepgram.types';

type Props = {
  role: Participants;
  content: string;
  index: number;
  handleChange: () => void;
  onAddNewRow: (i: number) => void;
};

const dropdownOptions = getParticipantsArray();

export default function EditableSentenceNode({ onAddNewRow, index, handleChange, role, content }: Props) {
  const [newRole, setNewRole] = useState<string>(role);
  const [newContent, setNewContent] = useState<string>(content);

  const handleRoleChange = (val: any) => {
    if (val) {
      setNewRole(val.name);
      handleChange(index, val.name, newContent);
    }
  };

  const handleContentChange = (val: string) => {
    setNewContent(val);
    handleChange(index, newRole, val);
  };

  return (
    <div className="flex justify-start items-end gap-x-4 w-full">
      <div className="flex flex-col gap-y-2">
        <InputLabel label="Role" />
        <Dropdown options={dropdownOptions} defaultValue={role} onSelect={handleRoleChange} />
      </div>

      <div className="flex flex-col gap-y-2 w-full">
        <InputLabel label="Content" />
        <TextField value={newContent} onChange={(e) => handleContentChange(e.target.value)} />
      </div>

      <IconButton title="Add new row below" onClick={() => onAddNewRow(index)}>
        <PlusCircleIcon className="w-5 h-5" />
      </IconButton>
    </div>
  );
}
