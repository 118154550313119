import { CallDetailsTab, IntentReasonMap } from 'src/typings/call.types';
import get from 'lodash/get';
import { CompanyNode } from 'src/typings/user.types';

export const getUserIntentReason = (intent: number, reason?: string) => {
  if (reason) return reason;
  if (intent === -1) return 'NA';
  return get(IntentReasonMap, intent, 'NA');
};

export const parseLatencyValues = (val: number) => {
  if (!val) return '-';
  return parseFloat(val.toString()).toFixed(2);
};

export const getXMinutesFromNow = (mins: number) => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + mins);
  return now;
};

export type SupportedCurrency = 'rupee';

export const formatDuration = (val: string | number) => {
  if (!val || val === 'NA' || val === '-') return 'NA';
  return `${val}s`;
};

export const formatCurrency = (amount: string | number, currency?: SupportedCurrency): string => {
  switch (currency) {
    case 'rupee':
      return `₹${amount}`;
    default:
      return `₹${amount}`;
  }
};

export const getCompanyNameFromCompanyId = (id: CompanyNode['id'], companyList: CompanyNode[]) => {
  const wsCompany = companyList.find((i) => i.id === id);
  if (wsCompany) {
    return wsCompany.name;
  } else {
    return id;
  }
};

export const filterAllowedTabs = (tabs: CallDetailsTab[]): CallDetailsTab[] => tabs.filter((tab) => tab.isAllowed);

export const getCallSummary = (data: string) => {
  try {
    const res = JSON.parse(data);
    return {
      delay_reason: res.delay_reason,
      borrower_attitude: res.borrower_attitude,
    };
  } catch (error) {
    return {
      summary: data,
    };
  }
};
